//This files contains the Admin component which is the main component for the admin page. It contains the AdminHeader, AdminSidebar and AdminContent components.
//AdminHeader contains the header of the admin page.
//AdminSidebar contains the sidebar of the admin page.
//AdminContent contains the content of the admin page.
import React, { useState } from "react";
import config from "./config.js";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import { Dashboard, Settings, ExitToApp } from "@mui/icons-material";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminContent from "./AdminContent";
import Logout from "./Logout";

const Admin = () => {
  const [open, setOpen] = useState(true);
  const [selectedSection, setSelectedSection] = useState("Dashboard");
  const [forceRender, setForceRender] = useState(0);
  const [subscribedPOId, setSubscribedPOId] = useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    //setOpen(false);
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    forceRender === 0 ? setForceRender(1) : setForceRender(0);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AdminHeader open={open} handleDrawerOpen={handleDrawerOpen} />
      <AdminSidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        onSectionChange={handleSectionChange}
      />
      {
        <AdminContent
          key={forceRender}
          selectedSection={selectedSection}
          subscribedPOId={subscribedPOId}
          setSubscribedPOId={setSubscribedPOId}
        />
      }
    </Box>
  );
};

export default Admin;
