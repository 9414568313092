import React, { useState, useEffect } from "react";
import config from "./config.js";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import { map } from "lodash";
import ProductionOrders from "./ProductionOrders";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";
import "./styles.css";

const Stations = () => {
  const [stations, setStations] = useState([]);
  const [currentStation, setCurrentStation] = useState({
    id: null,
    name: "",
    code: "",
    description: "",
    status: "",
    materials: "",
    incidences: "",
    antennas: "",
    station_type_id: "",
    line_id: "",
    status_id: "",
  });
  const [open, setOpen] = useState(false);
  const [lines, setLines] = useState([]); // Add line state
  const [checkedState, setCheckedState] = useState({});
  const [stationTypes, setStationTypes] = useState([]);
  const [stationStatuses, setStationStatuses] = useState([]);
  const [stationManagers, setStationManagers] = useState([]);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Fetching stations...");
    if (localStorage.getItem("role") === "Operator") {
      const stationId = fetchCurrentUserStationId();
      fetchStationsAndCheck(stationId);
    } else {
      fetchStations();
    }
    fetchLines(); // Fetch lines when component mounts
    fetchStationTypes();
    fetchStationStatuses();
    fetchStationManagers();
  }, []);
  const fetchStationManagers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + "/users/roles/StationManagement",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      setStationManagers(response.data);
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error fetching station managers:", error);
    }
  };

  const fetchStationTypes = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/station-types", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setStationTypes(response.data);
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error fetching station types:", error);
    }
  };

  const fetchStationStatuses = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/station-status", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setStationStatuses(response.data);
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error fetching station types:", error);
    }
  };

  const fetchStations = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/stations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);

        //filter stations result by StationManagement role
        if (localStorage.getItem("role") === "StationManagement") {
          const ManagedStation = data.filter(
            (station) => station.mgmt_user === localStorage.getItem("username")
          );
          setStations(ManagedStation);
        } else {
          setStations(data);
        }
      } else {
        console.error("Failed to fetch stations data");
        handleLogout();
      }
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error fetching stations:", error);
    }
  };

  const fetchStationsAndCheck = async (stationId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/stations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        setStations(response.data);
        console.log("stationId: ", stationId);

        const initialCheckedState = data.reduce((acc, station) => {
          acc[station.id] = false;
          console.log("station.id: ", station.id);
          return acc;
        }, {});
        setCheckedState(initialCheckedState);
        stationId = stationId.then((stationId) => {
          console.log("stationId: ", stationId);
          setCheckedState({ [stationId]: true });
        });
      } else {
        console.error("Failed to fetch stations data");
        handleLogout();
      }
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error fetching stations:", error);
    }
  };
  const fetchLines = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/lines", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setLines(response.data);
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error fetching lines:", error);
    }
  };

  const fetchCurrentUserStationId = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/user/${localStorage.getItem("username")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        return data.station_id;
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error fetching user:", error);
    }
  };

  const handleStationSubscription = async (stationId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        config.url + `/stations/${stationId}/subscribe`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        fetchStations();
      } else {
        console.error("Failed to subscribe to station");
      }
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error subscribing to station:", error);
    }
  };
  const handleStationUnsubscription = async (stationId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        config.url + `/stations/${stationId}/unsubscribe`,
        {},

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        fetchStations();
      } else {
        console.error("Failed to subscribe to station");
      }
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error subscribing to station:", error);
    }
  };
  const handleAddStation = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(config.url + "/stations", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.status === 201) {
        const data = await response.data;
        console.log(data);
        fetchStations();
      } else {
        console.error("Failed to add station");
      }
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error adding station:", error);
    }
  };

  const handleSelectClick = (station) => {
    setCurrentStation(station);
  };
  const handleOpen = (
    station = {
      id: null,
      name: "",
      code: "",
      description: "",
      status: "",
      materials: "",
      incidences: "",
      antennas: "",
      station_type_id: "",
      line_id: "",
    }
  ) => {
    setCurrentStation(station);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCurrentStation({ ...currentStation, [name]: value });
    setFormData({ ...formData, [name]: value });
  };

  const handleEditClick = (station) => {
    setCurrentStation(station);
    setFormData(station);
    setOpen(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      await axios.delete(config.url + `/stations/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchStations();
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // Unauthorized
          console.log("Unauthorized");
          handleLogout();
        }
      }
      console.error("Error deleting station:", error);
    }
  };

  const handleCheckBoxChange = (station) => (event) => {
    if (event.target.checked === false) {
      handleStationUnsubscription(station.id);
    }
    const updatedCheckedState = {
      ...checkedState,
      [station.id]: event.target.checked,
    };
    setCheckedState(updatedCheckedState);

    if (event.target.checked) {
      handleStationSubscription(station.id);
    }
    // all others to false
    Object.keys(updatedCheckedState).forEach((key) => {
      if (key != station.id) {
        updatedCheckedState[key] = false;
      }
    });
  };
  const handleSave = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (currentStation.id) {
      await axios.put(
        config.url + `/stations/${currentStation.id}`,
        currentStation,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } else {
      // Create new antenna
      await axios.post(config.url + "/stations", currentStation, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    fetchStations();
    handleClose();
  };
  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("stations")}
      </Typography>
      {localStorage.getItem("role") === "Admin" ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => handleOpen()}
        >
          {t("add_station")}
        </Button>
      ) : (
        <Typography variant="body1" className="custom-h4">
          {t("select_station")}
        </Typography>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("code")}</TableCell>
              <TableCell>{t("description")}</TableCell>
              {/*<TableCell>Materials</TableCell>*/}
              <TableCell>{t("current_production_orders")}</TableCell>
              <TableCell>{t("incidences")}</TableCell>
              <TableCell>{t("antennas")}</TableCell>
              <TableCell>{t("usernames")}</TableCell>
              <TableCell>{t("station_type_id")}</TableCell>
              <TableCell>{t("line_id")}</TableCell>
              <TableCell>{t("status")}</TableCell>
              {localStorage.getItem("role") === "Operator" ? (
                <TableCell>{t("current_station")}</TableCell>
              ) : null}
              {localStorage.getItem("role") === "Admin" ? (
                <>
                  <TableCell>{t("manager")}</TableCell>
                  <TableCell>{t("actions")}</TableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {stations.map((station) => (
              <TableRow key={station.id}>
                <TableCell>{station.name}</TableCell>
                <TableCell>{station.code}</TableCell>
                <TableCell>{station.description}</TableCell>
                {/*<TableCell>{station.materials}</TableCell>*/}
                <TableCell>{station.current_production_order_ids}</TableCell>
                <TableCell>{station.incidences}</TableCell>
                <TableCell>{station.antennas}</TableCell>
                <TableCell>{station.usernames}</TableCell>
                <TableCell>{station.station_type_id}</TableCell>
                <TableCell>{station.line_id}</TableCell>
                <TableCell style={{ backgroundColor: station.status_color }}>
                  {station.status}
                </TableCell>
                {localStorage.getItem("role") === "Operator" ? (
                  <TableCell>
                    <IconButton onClick={() => handleSelectClick(station)}>
                      <Checkbox
                        checked={checkedState[station.id] || false}
                        onChange={handleCheckBoxChange(station)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </IconButton>
                  </TableCell>
                ) : null}
                {localStorage.getItem("role") === "Admin" ? (
                  <>
                    <TableCell>{station.mgmt_user}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(station)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(station.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentStation.id ? t("edit_station") : t("add_station")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentStation.id ? t("edit_station_details") : t("add_station")}
          </DialogContentText>
          <TextField
            name="name"
            value={currentStation.name}
            onChange={handleChange}
            label={t("name")}
            fullWidth
            margin="dense"
          />
          <TextField
            name="code"
            value={currentStation.code}
            onChange={handleChange}
            label={t("code")}
            fullWidth
            margin="dense"
          />
          <TextField
            name="description"
            value={currentStation.description}
            onChange={handleChange}
            label={t("description")}
            fullWidth
            margin="dense"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Select Station Status</InputLabel>
            <Select
              name="status_id"
              value={currentStation.status_id}
              onChange={handleChange}
            >
              {map(stationStatuses, (stationStatus) => (
                <MenuItem key={stationStatus.id} value={stationStatus.id}>
                  {stationStatus.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>{t("select_station_type")}</InputLabel>
            <Select
              name="station_type_id"
              value={currentStation.station_type_id}
              onChange={handleChange}
            >
              {map(stationTypes, (stationType) => (
                <MenuItem key={stationType.id} value={stationType.id}>
                  {stationType.id + ": " + stationType.station_type_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>{t("manager")}</InputLabel>
            <Select
              name="mgmt_user"
              value={currentStation.mgmt_user}
              onChange={handleChange}
            >
              {map(stationManagers, (stationManager) => (
                <MenuItem
                  key={stationManager.id}
                  value={stationManager.username}
                >
                  {stationManager.id + ": " + stationManager.username}
                </MenuItem>
              ))}
              <MenuItem key={null} value={null}>
                {t("none")}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>{t("select_line")}</InputLabel>
            <Select
              name="line_id"
              value={currentStation.line_id}
              onChange={handleChange}
            >
              {map(lines, (line) => (
                <MenuItem key={line.id} value={line.id}>
                  {line.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Stations;
