import React, { useState, useEffect } from "react";
import config from "./config.js";
import axios from "axios";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

const Carts = () => {
  const [carts, setCarts] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentCart, setCurrentCart] = useState({
    id: null,
    reference: "",
    capacity: 0,
    description: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    fetchCarts();
  }, []);

  const fetchCarts = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url+"/carts", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setCarts(response.data);
    } catch (error) {
      console.error("Error fetching carts:", error);
    }
  };

  const handleOpen = (
    cart = {
      id: null,
      cartname: "",
      email: "",
      password: "",
      role_id: "",
      station_id: "",
    }
  ) => {
    console.log(cart);
    setCurrentCart(cart);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCurrentCart({ ...currentCart, [name]: value });
  };

  const handleSave = async () => {
    if (currentCart.id) {
      console.log(currentCart);
      // Update existing cart use bearer access_tokern
      const accessToken = localStorage.getItem("access_token");
      await axios.put(
        config.url+`/carts/${currentCart.id}`,
        currentCart,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } else {
      // Create new cart
      console.log(currentCart);
      const accessToken = localStorage.getItem("access_token");
      await axios.post(config.url+"/carts", currentCart, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    fetchCarts();
    handleClose();
  };

  const handleDelete = async (id) => {
    const accessToken = localStorage.getItem("access_token");
    await axios.delete(config.url+`/carts/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    fetchCarts();
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("carts")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpen()}
      >
        {t("add_cart")}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("reference")}</TableCell>
              <TableCell>{t("capacity")}</TableCell>
              <TableCell>{t("description")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {carts.map((cart) => (
              <TableRow key={cart.id}>
                <TableCell>{cart.reference_code}</TableCell>
                <TableCell>{cart.capacity}</TableCell>
                <TableCell>{cart.description}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpen(cart)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(cart.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        {console.log(currentCart)}
        <DialogTitle>
          {currentCart.id ? t("edit_cart") : t("add_cart")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentCart.id
              ? t("edit_cart_details")
              : t("enter_new_cart_details")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="reference_code"
            label={t("reference_code")}
            type="text"
            fullWidth
            value={currentCart.reference_code}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="capacity"
            label={t("capacity")}
            type="number"
            fullWidth
            value={currentCart.capacity}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="description"
            label={t("description")}
            type="text"
            fullWidth
            value={currentCart.description}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Carts;
