/* About us page with some information about the company and software version */

import React from "react";
import config from "./config.js";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VERSION } from "../config";
import { Box } from "@mui/system";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("about_us")}
      </Typography>
      <Typography variant="body1">DSA Grupo ® {t("about_us_text")}</Typography>
      <Typography variant="body1">
        {t("software_version")}: {VERSION}
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <img
          src="MESisa95.png"
          alt="MES ISA-95 Diagram"
          style={{ maxWidth: "50%", height: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default AboutUs;
