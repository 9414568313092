import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Paper,
  TextField,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Message from "./Message";
import ChartDisplay from "./ChartDisplay";
import { sendMessageToBackend } from "../api";

function Chat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return; // Evitar enviar mensajes vacíos

    setMessages([...messages, { text: newMessage, sender: "user" }]);
    setNewMessage(""); // Limpiar el campo de entrada

    try {
      const response = await sendMessageToBackend(newMessage);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: response.text,
          sender: "bot",
          chartData: response.data,
          visualization: response.visualization,
        },
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Error al conectar con el servidor.", sender: "bot" },
      ]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      {/* Cabecera */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            FlowAI
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Mensajes */}
      <Paper
        elevation={3}
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: 2,
          margin: 2,
          backgroundColor: "#ffffff",
        }}
      >
        {messages.map((message, index) => (
          <Box key={index} mb={2}>
            <Message message={message} />
            {message.chartData && (
              <ChartDisplay
                data={message.chartData}
                visualizationType={message.visualization}
              />
            )}
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Paper>

      {/* Entrada de mensajes */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 1,
          backgroundColor: "#ffffff",
          borderRadius: "4px",
          margin: 2,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Escribe tu mensaje..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
          sx={{ marginRight: 1 }}
        />
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          disabled={!newMessage.trim()}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Chat;
