import React, { useState, useEffect, useRef } from "react";
import useComponentVisibility from "../hooks/useComponentVisibility";

import config from "./config.js";
import axios from "axios";
import {
  TextField,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { Edit, Delete, Topic } from "@mui/icons-material";
import StationStats from "./StationStats";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";
import "./styles.css";
import SemiElaborates from "./SemiElaborates";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importar la localización en español
import "dayjs/locale/en"; // Importar la localización en inglés
import "dayjs/locale/fr"; // Importar la localización en francés
import "dayjs/locale/de"; // Importar la localización en alemán
import "dayjs/locale/it"; // Importar la localización en italiano
import "dayjs/locale/zh"; // Importar la localización en chino

const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short", // To include the GMT information
};

const LogisticsPO = () => {
  const [productionOrders, setProductionOrders] = useState([]);
  const [subProductionOrders, setSubProductionOrders] = useState([]);
  const [selectedProductionOrder, setSelectedProductionOrder] = useState(null);
  const [selectedPOMaterials, setSelectedPOMaterials] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [role, setRole] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [poToSubscribe, setPoToSubscribe] = useState(null);
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [carts, setCarts] = useState([]);
  const [productionOrderStatistics, setProductionOrderStatistics] =
    useState(null);
  const [statsLoading, setStatsLoading] = useState(false);
  const [statsError, setStatsError] = useState(null);

  const [semiElaborates, setSemiElaborates] = useState([]);
  const [semiElaboratesLoading, setSemiElaboratesLoading] = useState(false);
  const [semiElaboratesError, setSemiElaboratesError] = useState(null);
  //startDate 2 weeks ago by default
  const [startDate, setStartDate] = useState(dayjs().subtract(14, "day"));

  const [endDate, setEndDate] = useState(null);

  dayjs.locale(i18n.language);
  const [modulaStatuses, setModulaStatuses] = useState({});
  const [modulaStatusColors, setModulaStatusColors] = useState({});

  const [modulaStatusColor, setModulaStatusColor] = useState("green");
  const [modulaStatus, setModulaStatus] = useState("");
  const [isVisible, ref] = useComponentVisibility();
  const pollingRef = useRef({}); // To keep track of intervals for each command

  dayjs.locale(i18n.language);

  useEffect(() => {
    fetchPOStatuses();
    fetchPickedProductionOrders(startDate, endDate);
    setRole(localStorage.getItem("role"));
    fetchCarts();

    const interval = setInterval(() => {
      console.log(
        "Fetching production orders..." + startDate.format("YYYY-MM-DD")
      );
      fetchPickedProductionOrders(startDate, endDate);
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [startDate, endDate]);

  // **New Function to Fetch Semi-Elaborates**
  const handleSubmitKit = async (kitId, reference, po_id, source) => {
    if (kitId !== undefined && kitId !== null) {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axios.put(
          config.url + `/modula/${kitId}/V`,
          {
            reference,
            po_id,
            source,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          pollVStatus(kitId);
        } else {
          console.error("Failed to submit modula kit");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    }
  };
  useEffect(() => {
    selectedPOMaterials.forEach((material) => {
      pollVStatus(material.id);
    });
  }, [selectedPOMaterials]);

  const pollVStatus = (kitId) => {
    const checkStatus = async () => {
      if (!isVisible) {
        clearInterval(pollingRef.current[kitId]);
        return;
      }

      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axios.get(
          config.url + `/modula/check_status/${kitId}/V`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const status = response.data.status;
        const statusColor = response.data.status_color;

        setModulaStatus((prevStatuses) => ({
          ...prevStatuses,
          [kitId]: status,
        }));

        setModulaStatusColor((prevColors) => ({
          ...prevColors,
          [kitId]: statusColor,
        }));
        setModulaStatuses((prevStatuses) => ({
          ...prevStatuses,
          [kitId]: status,
        }));

        setModulaStatusColors((prevColors) => ({
          ...prevColors,
          [kitId]: statusColor,
        }));

        if (status === "Completed") {
          clearInterval(pollingRef.current[kitId]);
        }
      } catch (error) {
        console.error("Error checking status", error);
        clearInterval(pollingRef.current[kitId]);
      }
    };

    checkStatus();
  };

  const fetchSemiElaborates = async (id) => {
    setSemiElaboratesLoading(true);
    setSemiElaboratesError(null);
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        `${config.url}/production_orders/${encodeURIComponent(
          id
        )}/semi-elaborates`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Semi-elaborates data:", response.data);
        setSemiElaborates(
          response.data.filter(
            (material) => material.source.indexOf("vertical") !== -1
          )
        );
      } else {
        console.error("Failed to fetch semi-elaborates");
        setSemiElaborates([]);
        setSemiElaboratesError("Failed to fetch semi-elaborates.");
      }
    } catch (error) {
      handleErrorResponse(error);
      setSemiElaborates([]);
      setSemiElaboratesError(
        "An error occurred while fetching semi-elaborates."
      );
    } finally {
      setSemiElaboratesLoading(false);
    }
  };

  const fetchProductionOrderStatistics = async (id) => {
    setStatsLoading(true);
    setStatsError(null);
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        `${config.url}/production_orders/${encodeURIComponent(id)}/statistics`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setProductionOrderStatistics(response.data);
      } else {
        console.error("Failed to fetch production order statistics");
        setProductionOrderStatistics(null);
        setStatsError("Failed to fetch statistics.");
      }
    } catch (error) {
      handleErrorResponse(error);
      setProductionOrderStatistics(null);
      setStatsError("An error occurred while fetching statistics.");
    } finally {
      setStatsLoading(false);
    }
  };

  const fetchCarts = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/carts", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        console.log("Cart data:", response.data);
        setCarts(response.data);
      } else {
        console.error("Failed to fetch carts");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredProductionOrders = productionOrders.filter(
    (po) =>
      (po.id.toString().includes(filter) ||
        (po.parent_id && po.parent_id.toString().includes(filter)) ||
        (po.type && po.type.toLowerCase().includes(filter.toLowerCase()))) &&
      (statusFilter === "" ||
        po.status === statusFilter ||
        (statusFilter === "En proceso y En espera" &&
          (po.status === "En proceso" || po.status === "En espera")))
  );

  const paginatedProductionOrders = filteredProductionOrders.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const totalPages = Math.ceil(filteredProductionOrders.length / rowsPerPage);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const fetchPOMaterials = async (productionOrder) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url +
          `/production_orders/${encodeURIComponent(
            productionOrder.id
          )}/materials`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSelectedPOMaterials(
          response.data.filter(
            (material) => material.source.indexOf("vertical") !== -1
          )
        );
      } else {
        console.error("Failed to fetch materials data");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  const refreshStartDateData = async (startDate) => {
    setStartDate(startDate);
    fetchPickedProductionOrders(startDate, endDate);
  };

  const refreshEndDateData = async (endDate) => {
    setEndDate(endDate);
    fetchPickedProductionOrders(startDate, endDate);
  };

  const fetchPickedProductionOrders = async (startDate, endDate) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const url = config.url + "/production_orders/picked-orders";

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
          endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
        },
      });
      if (response.status === 200) {
        console.log("Production orders:", response.data);

        // Sort the production orders by start_datetime from older to newer
        const sortedOrders = response.data.sort(
          (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime)
        );

        setProductionOrders(sortedOrders);
        fetchSubProductionOrdersForEachParent(sortedOrders);
        console.log("Selected PO:", selectedProductionOrder);
      } else {
        console.error("Failed to fetch production orders");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchSubProductionOrdersForEachParent = async (productionOrders) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const ids = productionOrders.map((po) => po.id);

      const response = await axios.post(
        config.url + `/get_all_sub_nodes_for_each_parent`,
        { ids },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSubProductionOrders(response.data);
      } else {
        console.error("Failed to fetch sub-production orders");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchSubProductionOrders = async (parent_id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/get_all_sub_nodes/${parent_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSubProductionOrders((prev) => ({
          ...prev,
          [parent_id]: response.data,
        }));
      } else {
        console.error("Failed to fetch sub-production orders");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchPOStatuses = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + "/production_orders/statuses",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setStatuses(response.data);
      } else {
        console.error("Failed to fetch statuses");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  // **Update handleSelection to Fetch Semi-Elaborates**
  const handleSelection = (productionOrder) => {
    setSelectedProductionOrder(productionOrder);
    fetchPOMaterials(productionOrder);
    fetchSubProductionOrders(productionOrder.id);
    fetchProductionOrderStatistics(productionOrder.id); // Existing statistics fetch
    fetchSemiElaborates(productionOrder.id); // Fetch semi-elaborates
  };

  const handlePOPicking = async (productionOrderId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        config.url +
          `/production_orders/${encodeURIComponent(
            productionOrderId
          )}/trigger_picking`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Picking triggered successfully");
      } else {
        console.error("Failed to trigger picking");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleErrorResponse = (error) => {
    if (error.response && error.response.status > 400) {
      console.log("Unauthorized");
      handleLogout();
    }
    console.error("Error:", error);
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {selectedProductionOrder
          ? `${t("production_order")}: ${selectedProductionOrder.id}`
          : t("production_orders")}
      </Typography>
      {!selectedProductionOrder && (
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
          {console.log("Lang:", i18n.language)}
          <Box display="flex" justifyContent="space-between" mb={2}>
            <DatePicker
              label={t("first_initial_date")}
              format="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) => refreshStartDateData(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label={t("last_initial_date")}
              format="DD/MM/YYYY"
              value={endDate}
              onChange={(newValue) => refreshEndDateData(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>
      )}

      {!selectedProductionOrder && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label={t("filter_by_bathroom_id")}
            variant="outlined"
            value={filter}
            onChange={handleFilterChange}
            sx={{ mb: 2, width: "100%", maxWidth: "500px" }}
          />
        </Box>
      )}

      {selectedProductionOrder && (
        <>
          <Typography variant="h4" className="custom-h4">
            {t("materials")}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("material_id")}</TableCell>
                  <TableCell>{t("description")}</TableCell>
                  <TableCell>{t("reference")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("unit_of_measurement")}</TableCell>
                  <TableCell>{t("carbon_footprint_coefficient")}</TableCell>
                  <TableCell>{t("station_type")}</TableCell>
                  <TableCell>{t("source")}</TableCell>
                  <TableCell>{t("status")}</TableCell>
                  <TableCell>{t("Modula")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPOMaterials.map((material) => (
                  <TableRow key={material.id}>
                    <TableCell>{material.id}</TableCell>
                    <TableCell>{material.description}</TableCell>
                    <TableCell>{material.reference}</TableCell>
                    <TableCell>{material.quantity}</TableCell>
                    <TableCell>{material.unit_of_measurement}</TableCell>
                    <TableCell>
                      {material.carbon_footprint_coefficient}
                    </TableCell>
                    <TableCell>{material.station_type}</TableCell>
                    <TableCell>{material.source}</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          modulaStatusColor[material.id] || "transparent",
                      }}
                    >
                      {" "}
                      {console.log("Modula Status:", modulaStatus)}
                      {modulaStatus[material.id] || t("not_requested")}
                    </TableCell>

                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleSubmitKit(
                            material.id,
                            material.reference,
                            selectedProductionOrder.id,
                            material.source
                          )
                        }
                      >
                        <ArrowCircleDownIcon />
                        <Typography>{t("request_kit_upload")}</Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 4 }}>
            {semiElaboratesLoading && (
              <Typography variant="body1">
                {t("loading_semi_elaborates")}...
              </Typography>
            )}
            {semiElaboratesError && (
              <Typography variant="body1" color="error">
                {semiElaboratesError}
              </Typography>
            )}
          </Box>
        </>
      )}

      {filteredProductionOrders.length > 0 && (
        <>
          <Box sx={{ mt: 4 }} />
          {!selectedProductionOrder && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#f5f5f5", // Light gray background
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
                  }}
                >
                  <TableRow>
                    <TableCell>{t("order_id")}</TableCell>
                    <TableCell>{t("parent_id")}</TableCell>
                    <TableCell>{t("expiration_date")}</TableCell>
                    <TableCell>{t("description")}</TableCell>
                    <TableCell>{t("start_datetime")}</TableCell>
                    <TableCell>{t("end_datetime")}</TableCell>
                    <TableCell>{t("type")}</TableCell>
                    <TableCell>{t("planned_line")}</TableCell>
                    <TableCell>{t("current_station")}</TableCell>

                    <TableCell>{t("trigger_picking")}</TableCell>
                    <TableCell>{t("incidents")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProductionOrders.map((po) => (
                    <React.Fragment key={po.id}>
                      <TableRow onClick={() => handleSelection(po)}>
                        <TableCell>{po.id}</TableCell>
                        <TableCell>{po.parent_id}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.expiration_date))}
                        </TableCell>

                        <TableCell>{po.description}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.start_datetime))}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.end_datetime))}
                        </TableCell>
                        <TableCell>{po.type}</TableCell>
                        <TableCell>{po.planned_line}</TableCell>
                        <TableCell>{po.current_station}</TableCell>

                        <TableCell>
                          {po.parent_id === null ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                handlePOPicking(po.id);
                              }}
                            >
                              {t("start")}
                            </Button>
                          ) : (
                            t("use_button_from_parent")
                          )}
                        </TableCell>
                        <TableCell>
                          {po.incidents &&
                            Object.entries(po.incidents)
                              .filter(([key, value]) => value.includes("Open"))
                              .map(([key, value]) => (
                                <div key={key}>
                                  {key}: {value}
                                </div>
                              ))}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
            <Typography sx={{ ml: 2 }}>
              {t("page")} {page} {t("of")} {totalPages}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default LogisticsPO;
