import React, { useState, useEffect } from "react";
import config from "./config.js";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Edit, GetApp } from "@mui/icons-material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./styles.css";
const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short", // To include the GMT information
};
const Documentation = () => {
  const [documents, setDocuments] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    file: null,
    production_order_id: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/documents", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleOpen = (document = null) => {
    setOpen(true);
    setCurrentDocument(document);
    setFormData(
      document || {
        name: "",
        description: "",
        file: null,
        production_order_id: "",
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      await axios.delete(config.url + `/documents/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchDocuments();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleDownload = async (id, filename) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/documents/${id}/download`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = new FormData();
      form.append("name", formData.name);
      form.append("description", formData.description);
      form.append("production_order_id", formData.production_order_id);
      if (formData.file) {
        form.append("file", formData.file);
      }
      const accessToken = localStorage.getItem("access_token");

      const configh = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      if (currentDocument?.id) {
        await axios.put(
          config.url + `/documents/${currentDocument.id}`,
          form,
          configh
        );
      } else {
        await axios.post(config.url + "/documents", form, configh);
      }
      fetchDocuments();
      handleClose();
    } catch (error) {
      console.error("Error submitting document:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("documents")}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        {t("upload_document")}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("date")}</TableCell>
              <TableCell>{t("description")}</TableCell>
              <TableCell>{t("file")}</TableCell>
              <TableCell>{t("production_order_id")}</TableCell>
              <TableCell>{t("user")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow key={document.id}>
                <TableCell>{document.name}</TableCell>
                <TableCell>
                  {new Intl.DateTimeFormat(config.langTZ, options).format(
                    new Date(document.date)
                  )}
                </TableCell>
                <TableCell>{document.description}</TableCell>
                <TableCell>{document.filename}</TableCell>
                <TableCell>{document.production_order_id}</TableCell>
                <TableCell>{document.user}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpen(document)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(document.id)}>
                    <Delete />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleDownload(document.id, document.filename)
                    }
                  >
                    <GetApp />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("document")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("document_details")}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label={t("name")}
            type="text"
            fullWidth
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="description"
            name="description"
            label={t("description")}
            type="text"
            fullWidth
            value={formData.description}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="production_order_id"
            name="production_order_id"
            label={t("production_order_id")}
            type="text"
            fullWidth
            value={formData.production_order_id}
            onChange={handleChange}
          />
          {/*input en español*/}
          <Input
            margin="dense"
            id="file"
            name="file"
            type="file"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button onClick={handleSubmit}>{t("save")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Documentation;
