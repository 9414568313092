import React, { useState, useEffect } from "react";
import config from "./config.js";
import axios from "axios";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Logout from "./Logout";
import "./styles.css";

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [formData, setFormData] = useState({
    tag_pc: "",
    production_code: "",
    utc_time: "",
    type: "",
  });

  useEffect(() => {
    console.log("Fetching tags...");
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.get(config.url+"/tags", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data); // Use the data as needed
        setTags(response.data);
      } else {
        // Handle errors
        console.error("Failed to fetch dashboard data");
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditClick = (tag) => {
    setSelectedTag(tag);
    setFormData(tag);
  };

  const handleDeleteClick = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      await axios.delete(config.url+`/tags/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchTags();
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = localStorage.getItem("access_token");
      if (selectedTag) {
        await axios.put(
          config.url+`/tags/${selectedTag.id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } else {
        await axios.post(config.url+"/tags", formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      fetchTags();
      setSelectedTag(null);
      setFormData({
        tag_pc: "",
        production_code: "",
        utc_time: "",
        type: "",
      });
    } catch (error) {
      console.error("Error saving tag:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h5" component="h2" className="custom-h4">
        {selectedTag ? "Edit Tag" : "Add Tag"}
      </Typography>
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 4 }}
      >
        <TextField
          label="tag_pc"
          name="tag_pc"
          value={formData.tag_pc}
          onChange={handleInputChange}
          fullWidth
        />
        <TextField
          label="production_code"
          name="production_code"
          value={formData.production_code}
          onChange={handleInputChange}
          fullWidth
        />
        <TextField
          label="utc_time"
          name="utc_time"
          value={formData.utc_time}
          onChange={handleInputChange}
          fullWidth
        />
        <TextField
          label="type"
          name="type"
          value={formData.type}
          onChange={handleInputChange}
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary">
          {selectedTag ? "Update Tag" : "Add Tag"}
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>tag_pc</TableCell>
              <TableCell>production_code</TableCell>
              <TableCell>utc_time</TableCell>
              <TableCell>type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((tag) => (
              <TableRow key={tag.tag_pc}>
                <TableCell>{tag.tag_pc}</TableCell>
                <TableCell>{tag.production_code}</TableCell>
                <TableCell>{tag.utc_time}</TableCell>
                <TableCell>{tag.type}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleEditClick(tag)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteClick(tag.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Logout />
    </Box>
  );
};

export default Tags;
