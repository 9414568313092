import React, { useState, useEffect } from "react";
import config from "./config.js";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Tab,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { map } from "lodash";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [station, setStation] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    id: null,
    username: "",
    email: "",
    password: "",
    role_id: "",
    role_name: "",
    station_id: "",
    station_code: "",
    company_worker_id: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchUsers();
    fetchRoles(); // Fetch roles when component mounts
  }, []);

  const fetchUsers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/users", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      handleLogout();
    }
  };

  const fetchRoles = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/roles", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      handleLogout();
    }
  };

  const handleOpen = (
    user = {
      id: null,
      username: "",
      email: "",
      password: "",
      role_id: "",
      role_name: "",
      station_id: "",
      station_code: "",
      company_worker_id: "",
    }
  ) => {
    console.log(user);
    setCurrentUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const handleSave = async () => {
    if (currentUser.id) {
      console.log(currentUser);
      // Update existing user use bearer access_tokern
      const accessToken = localStorage.getItem("access_token");
      await axios.put(config.url + `/users/${currentUser.id}`, currentUser, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } else {
      // Create new user
      console.log(currentUser);
      const accessToken = localStorage.getItem("access_token");
      await axios.post(config.url + "/users", currentUser, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    fetchUsers();
    handleClose();
  };

  const handleRemove = async (id) => {
    const accessToken = localStorage.getItem("access_token");
    await axios.delete(config.url + `/users/${id}/remove/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    fetchUsers();
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("users")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpen()}
      >
        {t("add_user")}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("username")}</TableCell>
              <TableCell>{t("company_worker_id")}</TableCell>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("email")}</TableCell>
              <TableCell>{t("role")}</TableCell>
              <TableCell>{t("station")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.company_worker_id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role_name}</TableCell>
                <TableCell>{user.station_code}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpen(user)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemove(user.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        {console.log(currentUser)}
        <DialogTitle>
          {currentUser.id ? t("edit_user") : t("add_user")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentUser.id
              ? t("edit_user_details")
              : t("enter_new_user_details")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label={t("username")}
            type="text"
            fullWidth
            value={currentUser.username}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="name"
            label={t("name")}
            type="text"
            fullWidth
            value={currentUser.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="company_worker_id"
            label={t("company_worker_id")}
            type="text"
            fullWidth
            value={currentUser.company_worker_id}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label={t("email")}
            type="email"
            fullWidth
            value={currentUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="password"
            label={t("password")}
            type="text"
            fullWidth
            value={currentUser.password}
            onChange={handleChange}
          />

          <FormControl fullWidth margin="dense">
            <InputLabel id="role-select-label">{t("role")}</InputLabel>
            <Select
              labelId="role-select-label"
              name="role_id"
              value={currentUser.role_id}
              onChange={handleChange}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Users;
