// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-h4 {
  font-size: 1.25rem;
  font-weight: 300;
  margin-top: 32px !important; /* Forzar margen superior */
  margin-bottom: 24px !important; /* Forzar margen inferior */
  color: #333;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

.custom-h4::after {
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  background: #2196f3;
  margin-top: 8px;
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,2BAA2B,EAAE,2BAA2B;EACxD,8BAA8B,EAAE,2BAA2B;EAC3D,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".custom-h4 {\n  font-size: 1.25rem;\n  font-weight: 300;\n  margin-top: 32px !important; /* Forzar margen superior */\n  margin-bottom: 24px !important; /* Forzar margen inferior */\n  color: #333;\n  line-height: 1.6;\n  letter-spacing: 0.5px;\n}\n\n.custom-h4::after {\n  content: \"\";\n  display: block;\n  width: 50px;\n  height: 2px;\n  background: #2196f3;\n  margin-top: 8px;\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
