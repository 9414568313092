import React from "react";
import config from "./config.js";
import { AppBar, Toolbar, Typography, IconButton, Box } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const AdminHeader = ({ open, handleDrawerOpen }) => {
  const { t } = useTranslation();
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <Menu />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          {t("admin_view")} {" - "}
          {localStorage.getItem("username")}
        </Typography>
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "left" }}>
          {/* add a png logo here and then text*/}
          <img
            src="FC.png"
            alt="logo"
            style={{ width: "30px", height: "30px" }}
          />
          <Typography variant="h6" noWrap>
            {"FlowControl"}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AdminHeader;
