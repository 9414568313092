//subscribe operators to station with DialogBox add, and delete action.

import React, { useState, useEffect } from "react";
import config from "./config.js";
import axios from "axios";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { handleLogout } from "./Logout";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

const StationOperators = () => {
  const [operators, setOperators] = useState([]);
  const [subscribedOperators, setSubscribedOperators] = useState([]);
  const [stations, setStations] = useState(["hola"]);
  const [station, setStation] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentOperator, setCurrentOperator] = useState({
    id: null,
    name: "",
    email: "",
    station_id: "",
  });
  const [formData, setFormData] = useState({
    name: "hola",
    email: "",
    station_id: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Fetching operators...");
    fetchOperators();
    console.log("stations:", stations);
    console.log("Station:", station);
  }, []);

  const fetchOperators = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/operators", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const sortedOperators = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setOperators(sortedOperators);
      fetchStations();
    } catch (error) {
      console.error("Error fetching operators:", error);
    }
  };
  const fetchSubscribedOperators = async (stationx) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      //get subscribed operators for station id variable = id
      const response = await axios.get(
        config.url + "/stations/" + stationx.id + "/operators",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setSubscribedOperators(response.data);
    } catch (error) {
      console.error("Error fetching operators:", error);
    }
  };

  const fetchStations = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/stations", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setStations(response.data);
      console.info("Stations response:", response.data);
      const stationx = response.data.find(
        (stationy) => stationy.mgmt_user === localStorage.getItem("username")
      );
      setStation(stationx);
      console.log("Stations response:", response.data);
      console.log("Station :", stationx);
      fetchSubscribedOperators(stationx);
    } catch (error) {
      console.error("Error fetching stations:", error);
    }
  };

  const handleOpen = (operator = null) => {
    if (operator) {
      setCurrentOperator(operator);
      setFormData({
        name: operator.name,
        email: operator.email,
        station_id: operator.station_id,
      });
    } else {
      setCurrentOperator(null);
      setFormData({
        name: "",
        email: "",
        station_id: "",
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log(event.target.name, event.target.value);

    //get username from operator by id key
    const operator = operators.find(
      (operator) => operator.id === event.target.value
    );
    console.log(operator);
    setFormData({
      ...formData,
      username: operator.username,
      name: operator.name,
      id: operator.id,
    });
    setCurrentOperator(operator);
  };

  const handleAdd = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      let response;
      console.log("Current operator:", currentOperator);
      console.log("Station:", station);
      response = await axios.post(
        config.url +
          "/stations/" +
          station.id +
          "/operator/" +
          currentOperator.id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 401) {
        handleLogout();
      }

      console.log(response);
      fetchOperators();
      handleClose();
    } catch (error) {
      console.error("Error saving operator:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      await axios.delete(
        config.url + "/stations/" + station.id + "/operator/" + id,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchOperators();
    } catch (error) {
      console.error("Error deleting operator:", error);
    }
  };

  const handleShiftChange = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      let response;

      for (let i = 0; i < subscribedOperators.length; i++) {
        response = await axios.delete(
          config.url +
            "/stations/" +
            station.id +
            "/operator/" +
            subscribedOperators[i].id,
          subscribedOperators[i],
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 401) {
          handleLogout();
        }
      }
      console.log(response);
      fetchOperators();
      handleClose();
    } catch (error) {
      console.error("Error saving operator:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("station_subscribed_operators")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpen()}
      >
        {t("subscribe_operator")}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        startIcon={<Delete />}
        onClick={() => handleShiftChange()}
        style={{ marginLeft: "20px" }}
      >
        {t("end_shift")}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("username")}</TableCell>
              <TableCell>{t("station")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(subscribedOperators, (soperator) => (
              <TableRow key={soperator.id}>
                <TableCell>{soperator.name}</TableCell>
                <TableCell>{soperator.username}</TableCell>
                <TableCell>{soperator.station_code}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(soperator.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("add") + " " + t("operator")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>{t("operators")}</InputLabel>
            <Select name="name" value={formData.id} onChange={handleChange}>
              {map(operators, (operator) => (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="username"
            label={t("username")}
            type="text"
            disabled
            fullWidth
            value={formData.username}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={handleAdd}>{t("add")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StationOperators;
