import React from "react";
import config from "./config.js";
import { Button, Box, Typography } from "@mui/material";
import axios from "axios";
import Login from "./Login";
import { useTranslation } from "react-i18next";
import "./styles.css";

export const handleLogout = () => {
  const session_id = localStorage.getItem("session_id");
  localStorage.removeItem("access_token");
  localStorage.removeItem("session_id");
  localStorage.removeItem("username");
  localStorage.removeItem("role");
  window.location.href = "/";
  axios
    .post(config.url+"/logout", {
      session_id: session_id,
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error("Error logging out:", error);
    });
  return <Login />;
};

const Logout = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="body1" className="custom-h4">
        {t("user")}: {localStorage.getItem("username")}
      </Typography>
      <Typography variant="body1" className="custom-h4">
        {t("role")}: {localStorage.getItem("role")}
      </Typography>
      <Button variant="contained" color="secondary" onClick={handleLogout}>
        {t("logout")}
      </Button>
    </Box>
  );
};

export default Logout;
