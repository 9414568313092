import React, { useState } from "react";
import config from "./config.js";
import LangChanger from "./LangChanger";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const LoginContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

const LoginBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "2rem",
  borderRadius: "8px",
  boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
});

const LoginForm = styled(Box)({
  width: "100%",
  marginTop: "1rem",
});

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const response = await fetch(config.url + "/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (response.ok) {
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("session_id", data.session_id);
      localStorage.setItem("username", username);
      localStorage.setItem("role", data.role);
      // Redirect to dashboard or another protected route
      if (data.role === "Admin") {
        window.location.href = "/admin";
      }
      if (data.role === "Operator") {
        window.location.href = "/operator";
      }
      if (data.role === "StationManagement") {
        window.location.href = "/station-management";
      }
      if (data.role === "Logistics") {
        window.location.href = "/logistics";
      }
    } else {
      setError(data.msg);
    }
  };

  return (
    <LoginContainer maxWidth="xs">
      <LangChanger />
      <img
        src="flowcontrol.png"
        alt="flowcontrol"
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px",
          marginBottom: "100px",
        }}
      />
      <LoginBox>
        <Typography variant="h4" component="h1">
          {t("login")}
        </Typography>
        <LoginForm component="form" onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t("username")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t("password")}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("login")}
          </Button>
        </LoginForm>
      </LoginBox>
    </LoginContainer>
  );
}

export default Login;
