import React, { useState, useEffect, useMemo, useCallback } from "react";
import config from "./config.js";

import ChildrenCartsByParentId from "./ChildrenCartsByParentId";
import axios from "axios";
import {
  TextField,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
} from "@mui/material";
import StationStats from "./StationStats";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";
import "./styles.css";
import SemiElaborates from "./SemiElaborates";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importar la localización en español
import "dayjs/locale/en"; // Importar la localización en inglés
import "dayjs/locale/fr"; // Importar la localización en francés
import "dayjs/locale/de"; // Importar la localización en alemán
import "dayjs/locale/it"; // Importar la localización en italiano
import "dayjs/locale/zh"; // Importar la localización en chino
import {
  Edit,
  Delete,
  Topic,
  AddCircle,
  RemoveCircle,
} from "@mui/icons-material";

const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short", // To include the GMT information
};

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const ProductionOrders = ({ subscribedPOId, setSubscribedPOId }) => {
  const [productionOrders, setProductionOrders] = useState([]);
  const [selectedProductionOrder, setSelectedProductionOrder] = useState(null);
  const [selectedPOMaterials, setSelectedPOMaterials] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [role, setRole] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [poToSubscribe, setPoToSubscribe] = useState(null);
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("En proceso y En espera");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [carts, setCarts] = useState([]);
  const [productionOrderStatistics, setProductionOrderStatistics] =
    useState(null);
  const [statsLoading, setStatsLoading] = useState(false);
  const [statsError, setStatsError] = useState(null);

  const [semiElaborates, setSemiElaborates] = useState([]);
  const [semiElaboratesLoading, setSemiElaboratesLoading] = useState(false);
  const [semiElaboratesError, setSemiElaboratesError] = useState(null);
  // Fecha de inicio 2 semanas atrás por defecto
  const [startDate, setStartDate] = useState(dayjs().subtract(31, "day"));
  const [searchLine, setSearchLine] = useState(localStorage.getItem("line"));
  const [lines, setLines] = useState([]);

  const [endDate, setEndDate] = useState(null);
  const [selectedCarts, setSelectedCarts] = useState([]); // Nuevo estado para múltiples carros
  const [cartAntennas, setCartAntennas] = useState({}); // Estado para antenas asociadas a cada carro
  const [pickingStatus, setPickingStatus] = useState("No iniciado");

  dayjs.locale(i18n.language);

  useEffect(() => {
    console.time("fetchPOStatuses");
    fetchPOStatuses();
    console.timeEnd("fetchPOStatuses");

    console.time("fetchProductionOrders");
    fetchProductionOrders(startDate, endDate);
    console.timeEnd("fetchProductionOrders");

    setRole(localStorage.getItem("role"));

    console.time("fetchUserData");
    fetchUserData();
    console.timeEnd("fetchUserData");

    console.time("fetchCarts");
    fetchCarts();
    console.timeEnd("fetchCarts");

    console.time("fetchLines");
    fetchLines();
    console.timeEnd("fetchLines");

    //const interval = setInterval(() => {
    //  console.log(
    //    "Obteniendo órdenes de producción..." + startDate.format("YYYY-MM-DD")
    //  );
    //  console.time("fetchProductionOrdersInterval");
    //  fetchProductionOrders(startDate, endDate);
    //  console.timeEnd("fetchProductionOrdersInterval");
    //}, 300000); // 60000 milisegundos = 1 minuto
    //
    //// Limpiar el intervalo al desmontar el componente
    //return () => clearInterval(interval);
  }, [startDate, endDate]);

  const fetchLines = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("fetchLinesRequest");
      const response = await axios.get(config.url + "/lines", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.timeEnd("fetchLinesRequest");
      console.log(response);
      setLines(response.data);
    } catch (error) {
      if ("response" in error && "status" in error.response) {
        if (error.response.status > 400) {
          // No autorizado
          console.log("No autorizado");
          handleLogout();
        }
      }
      console.error("Error al obtener líneas:", error);
    }
  }, []);
  const fetchPickingStatus = useCallback(async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("fetchPickingStatusRequest");
      const response = await axios.get(
        `${config.url}/production_orders/${encodeURIComponent(
          id
        )}/picking_status`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("fetchPickingStatusRequest");
      console.log("Picking status:", response.data);
      setPickingStatus(response.data);
    } catch (error) {
      console.error("Error al obtener el estado de picking:", error);
    }
  }, []);

  useEffect(() => {
    if (selectedProductionOrder) {
      console.log("Carts:", selectedProductionOrder.carts);
      setSelectedCarts(selectedProductionOrder.carts || []);
      fetchAntennaForCarts(selectedProductionOrder.carts || []);
      fetchPickingStatus(selectedProductionOrder.id);
    }
  }, [selectedProductionOrder]);

  const fetchSemiElaborates = useCallback(async (id) => {
    setSemiElaboratesLoading(true);
    setSemiElaboratesError(null);
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("fetchSemiElaboratesRequest");
      const response = await axios.get(
        `${config.url}/production_orders/${encodeURIComponent(
          id
        )}/semi-elaborates`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("fetchSemiElaboratesRequest");
      if (response.status === 200) {
        setSemiElaborates(response.data);
      } else {
        console.error("Error al obtener semi-elaborados");
        setSemiElaborates([]);
        setSemiElaboratesError("Error al obtener semi-elaborados.");
      }
    } catch (error) {
      handleErrorResponse(error);
      setSemiElaborates([]);
      setSemiElaboratesError("Ocurrió un error al obtener semi-elaborados.");
    } finally {
      setSemiElaboratesLoading(false);
    }
  }, []);

  const fetchProductionOrderStatistics = useCallback(async (id) => {
    setStatsLoading(true);
    setStatsError(null);
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("fetchProductionOrderStatisticsRequest");
      const response = await axios.get(
        `${config.url}/production_orders/${encodeURIComponent(id)}/statistics`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("fetchProductionOrderStatisticsRequest");
      if (response.status === 200) {
        setProductionOrderStatistics(response.data);
      } else {
        console.error(
          "Error al obtener estadísticas de la orden de producción"
        );
        setProductionOrderStatistics(null);
        setStatsError("Error al obtener estadísticas.");
      }
    } catch (error) {
      handleErrorResponse(error);
      setProductionOrderStatistics(null);
      setStatsError("Ocurrió un error al obtener estadísticas.");
    } finally {
      setStatsLoading(false);
    }
  }, []);

  const fetchCarts = async () => {
    try {
      const response = await axios.get(`${config.url}/carts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (response.status === 200) setCarts(response.data);
    } catch (error) {
      console.error("Error al obtener carros", error);
    }
  };

  const fetchAntennaForCarts = async (carts) => {
    if (!carts.length) {
      setCartAntennas({});
      return;
    }
    try {
      const response = await axios.get(`${config.url}/carts/antenna`, {
        params: {
          cart_ids: carts
            .map((cart) => cart.id)
            .filter((id) => id !== undefined),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (response.status === 200) {
        const antennasData = response.data.reduce((acc, item) => {
          acc[item.cart_id] = item.antenna_name;
          return acc;
        }, {});
        setCartAntennas(antennasData);
      }
    } catch (error) {
      console.error("Error al obtener antenas", error);
    }
  };

  const fetchUserData = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("fetchUserDataRequest");
      const response = await axios.get(
        config.url + "/user/" + localStorage.getItem("username"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("fetchUserDataRequest");
      if (response.status === 200) {
        console.log("Datos del usuario:", response.data);
        setSubscribedPOId(response.data.production_order_id);
      } else {
        console.error("Error al obtener datos del usuario");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredProductionOrders = useMemo(() => {
    return productionOrders.filter(
      (po) =>
        (po.id.toString().includes(filter) ||
          (po.parent_id && po.parent_id.toString().includes(filter)) ||
          (po.type && po.type.toLowerCase().includes(filter.toLowerCase()))) &&
        (statusFilter === "" ||
          po.status === statusFilter ||
          (statusFilter === "En proceso y En espera" &&
            (po.status === "En proceso" || po.status === "En espera"))) &&
        (po.current_line === searchLine || searchLine === "LX")
    );
  }, [productionOrders, filter, statusFilter, searchLine]);

  const paginatedProductionOrders = useMemo(() => {
    return filteredProductionOrders.slice(
      (page - 1) * rowsPerPage,
      page * rowsPerPage
    );
  }, [filteredProductionOrders, page, rowsPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredProductionOrders.length / rowsPerPage);
  }, [filteredProductionOrders, rowsPerPage]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const fetchPOMaterials = useCallback(async (productionOrder) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("fetchPOMaterialsRequest");
      const response = await axios.get(
        config.url +
          `/production_orders/${encodeURIComponent(
            productionOrder.id
          )}/materials`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("fetchPOMaterialsRequest");
      if (response.status === 200) {
        setSelectedPOMaterials(response.data);
      } else {
        console.error("Error al obtener datos de materiales");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }, []);

  const refreshStartDateData = useCallback(
    debounce(async (startDate) => {
      if (startDate && startDate.isValid()) {
        setStartDate(startDate);
        console.time("fetchProductionOrdersStartDate");
        fetchProductionOrders(startDate, endDate);
        console.timeEnd("fetchProductionOrdersStartDate");
      }
    }, 500),
    [endDate]
  );

  const refreshEndDateData = useCallback(
    debounce(async (endDate) => {
      if (endDate && endDate.isValid()) {
        setEndDate(endDate);
        console.time("fetchProductionOrdersEndDate");
        fetchProductionOrders(startDate, endDate);
        console.timeEnd("fetchProductionOrdersEndDate");
      }
    }, 500),
    [startDate]
  );

  const fetchProductionOrders = useCallback(
    debounce(async (startDate, endDate) => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const isStationManagement =
          localStorage.getItem("role") === "StationManagement";
        const url = isStationManagement
          ? config.url + "/production_orders/station-management"
          : config.url + "/all_production_orders";

        console.time("fetchProductionOrdersRequest");
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
            endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
          },
        });
        console.timeEnd("fetchProductionOrdersRequest");

        if (response.status === 200) {
          console.log("Órdenes de producción:", response.data);

          let sortedOrders;

          // Usar la nueva lógica de ordenación solo para /all_production_orders
          if (!isStationManagement) {
            // Nueva lógica: Estado "En proceso" al principio, luego estación actual, luego start_datetime.
            sortedOrders = response.data.sort((a, b) => {
              const aEnProceso = a.status === "En proceso";
              const bEnProceso = b.status === "En proceso";

              // Órdenes con estado "En proceso" van primero
              if (aEnProceso && !bEnProceso) return -1;
              if (!aEnProceso && bEnProceso) return 1;

              // Si ambos (o ninguno) tienen estado "En proceso", aplicar la lógica original
              const aHasStation =
                a.current_station_id !== null && a.current_station_id !== "";
              const bHasStation =
                b.current_station_id !== null && b.current_station_id !== "";

              // Órdenes con un current_station_id válido van primero (solo si no ambos son "En proceso")
              if (aHasStation && !bHasStation) return -1;
              if (!aHasStation && bHasStation) return 1;

              // De lo contrario, ordenar por start_datetime (más antiguo a más reciente)
              return new Date(a.start_datetime) - new Date(b.start_datetime);
            });
          } else {
            // Lógica antigua: Ordenar solo por start_datetime (más antiguo a más reciente)
            sortedOrders = response.data.sort(
              (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime)
            );
          }

          // Mantener la lógica para que la orden de producción suscrita quede en la parte superior
          if (subscribedPOId) {
            let newSortedOrders = sortedOrders.filter(
              (po) => po.id === subscribedPOId
            );
            newSortedOrders = newSortedOrders.concat(
              sortedOrders.filter((po) => po.id !== subscribedPOId)
            );
            sortedOrders = newSortedOrders;
          }

          // Establecer el campo incidentColor basado en incidentes 'Open'
          for (let i = 0; i < sortedOrders.length; i++) {
            let po = sortedOrders[i];
            let incidentColor = "none";
            for (let j = 0; j < po.incidents.length; j++) {
              if (po.incidents[j].status === "Open") {
                incidentColor = "red";
                break;
              }
            }
            sortedOrders[i].incidentColor = incidentColor;
          }

          setProductionOrders(sortedOrders);
          console.log("Órden seleccionada:", selectedProductionOrder);

          setSelectedCarts((prevCarts) =>
            prevCarts.length
              ? sortedOrders.find((po) => po.id === selectedProductionOrder)
                  ?.carts || []
              : []
          );
        } else {
          console.error("Error al obtener órdenes de producción");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    }, 500),
    [subscribedPOId, selectedProductionOrder]
  );

  const fetchPOStatuses = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("fetchPOStatusesRequest");
      const response = await axios.get(
        config.url + "/production_orders/statuses",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("fetchPOStatusesRequest");
      if (response.status === 200) {
        setStatuses(response.data);
      } else {
        console.error("Error al obtener estados");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }, []);

  // **Actualizar handleSelection para obtener Semi-Elaborates**
  const handleSelection = useCallback(
    (productionOrder) => {
      setSelectedProductionOrder(productionOrder);
      console.time("fetchPOMaterials");
      fetchPOMaterials(productionOrder);
      console.timeEnd("fetchPOMaterials");
      console.time("fetchProductionOrderStatistics");
      fetchProductionOrderStatistics(productionOrder.id); // Obtener estadísticas existentes
      console.timeEnd("fetchProductionOrderStatistics");
      console.time("fetchSemiElaborates");
      fetchSemiElaborates(productionOrder.id); // Obtener semi-elaborados
      console.timeEnd("fetchSemiElaborates");
    },
    [fetchPOMaterials, fetchProductionOrderStatistics, fetchSemiElaborates]
  );

  const handlePOSubscription = useCallback(
    async (productionOrder) => {
      try {
        const accessToken = localStorage.getItem("access_token");
        console.time("handlePOSubscriptionRequest");
        const response = await axios.post(
          config.url +
            `/production_orders/${encodeURIComponent(
              productionOrder
            )}/subscribe`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.timeEnd("handlePOSubscriptionRequest");
        if (response.status === 200) {
          setSubscribedPOId(productionOrder);
          updateStatus(
            productionOrder,
            statuses.find((status) => status.name === "En proceso")
          );
          // Cambiar el estado a "En proceso" automáticamente
          const inProgressStatus = statuses.find(
            (status) => status.name === "En proceso"
          );
          if (inProgressStatus) {
            await updateStatus({ id: productionOrder }, inProgressStatus);
          }

          updateProductionOrderState(productionOrder, true);
        } else {
          console.error("Error al suscribirse");
          // Si es 404, alertar que se necesita tener operadores suscritos a la estación
          alert("No hay operadores suscritos a la estación");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("No hay operadores suscritos a la estación");
      }
    },
    [statuses]
  );

  const handlePOUnsubscription = useCallback(async (productionOrder) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("handlePOUnsubscriptionRequest");
      const response = await axios.post(
        config.url +
          `/production_orders/${encodeURIComponent(
            productionOrder
          )}/unsubscribe`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("handlePOUnsubscriptionRequest");
      if (response.status === 200) {
        setSubscribedPOId(null);
        updateProductionOrderState(productionOrder, false);
      } else {
        console.error("Error al desuscribirse");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const updateProductionOrderState = useCallback(
    (productionOrderId, isSubscribed) => {
      const currentUsername = localStorage.getItem("username");

      setProductionOrders((prevOrders) =>
        prevOrders.map((po) =>
          po.id === productionOrderId
            ? {
                ...po,
                usernames: isSubscribed
                  ? [...po.usernames, currentUsername]
                  : po.usernames.filter(
                      (username) => username !== currentUsername
                    ),
              }
            : po
        )
      );

      if (selectedProductionOrder?.id === productionOrderId) {
        setSelectedProductionOrder((prevOrder) =>
          prevOrder
            ? {
                ...prevOrder,
                usernames: isSubscribed
                  ? [...prevOrder.usernames, currentUsername]
                  : prevOrder.usernames.filter(
                      (username) => username !== currentUsername
                    ),
              }
            : null
        );
      }
    },
    [selectedProductionOrder]
  );

  const handleStatusChangeRequest = (event) => {
    const selectedStatus = statuses.find(
      (status) => status.name === event.target.value
    );
    setNewStatus(selectedStatus);
    setStatusDialogOpen(true);
  };

  const handleConfirmStatusChange = useCallback(async () => {
    setStatusDialogOpen(false);
    if (
      selectedProductionOrder?.usernames.includes(
        localStorage.getItem("username")
      ) ||
      role === "Admin"
    ) {
      console.time("updateStatus");
      await updateStatus(selectedProductionOrder, newStatus);
      console.timeEnd("updateStatus");
    } else {
      console.warn(
        "Debes estar suscrito a esta orden de producción para cambiar su estado."
      );
    }
  }, [selectedProductionOrder, newStatus, role]);

  const handleCancelStatusChange = () => {
    setStatusDialogOpen(false);
    setNewStatus(null);
  };

  const handleCheckBoxChange = (productionOrderId) => async (event) => {
    event.stopPropagation();
    setPoToSubscribe(productionOrderId);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmSubscription = useCallback(async () => {
    setConfirmationDialogOpen(false);
    if (subscribedPOId === poToSubscribe) {
      console.time("handlePOUnsubscription");
      await handlePOUnsubscription(poToSubscribe);
      console.timeEnd("handlePOUnsubscription");
    } else {
      if (subscribedPOId) {
        console.time("handlePOUnsubscription");
        await handlePOUnsubscription(subscribedPOId);
        console.timeEnd("handlePOUnsubscription");
      }
      console.time("handlePOSubscription");
      await handlePOSubscription(poToSubscribe);
      console.timeEnd("handlePOSubscription");
    }
    console.time("fetchProductionOrders");
    fetchProductionOrders(startDate, endDate);
    console.timeEnd("fetchProductionOrders");
  }, [subscribedPOId, poToSubscribe, startDate, endDate]);

  const handleCancelSubscription = () => {
    setConfirmationDialogOpen(false);
    setPoToSubscribe(null);
  };

  const handleStatusChange = (event) => {
    const selectedStatus = statuses.find(
      (status) => status.name === event.target.value
    );
    if (
      selectedProductionOrder?.usernames.includes(
        localStorage.getItem("username")
      )
    ) {
      console.time("updateStatus");
      updateStatus(selectedProductionOrder, selectedStatus);
      console.timeEnd("updateStatus");
    } else {
      console.warn(
        "Debes estar suscrito a esta orden de producción para cambiar su estado."
      );
    }
  };

  const handleCartChange = useCallback(
    async (event) => {
      console.log("Carts:", carts);
      console.log(selectedProductionOrder.cart);

      const selectedCart = carts.find(
        (cart) => cart.reference_code === event.target.value
      );
      console.log(event.target.value);
      console.log("Selected cart:", selectedCart);

      if (
        selectedProductionOrder?.usernames.includes(
          localStorage.getItem("username")
        ) ||
        role === "Admin" ||
        role === "StationManagement"
      ) {
        if (selectedCart) {
          console.time("linkCart");
          linkCart(selectedProductionOrder, selectedCart);
          console.timeEnd("linkCart");
        } else {
          console.time("unlinkCart");
          unlinkCart(selectedProductionOrder, selectedProductionOrder.cart);
          console.timeEnd("unlinkCart");
        }
      } else {
        console.warn(
          "Debes estar suscrito a esta orden de producción para cambiar su carrito."
        );
      }
    },
    [carts, selectedProductionOrder, role]
  );

  const linkCart = useCallback(
    async (productionOrder, cart) => {
      const reference_code = cart ? cart.reference_code : null;
      try {
        const accessToken = localStorage.getItem("access_token");
        console.time("linkCartRequest");
        const response = await axios.put(
          config.url +
            `/production_orders/${encodeURIComponent(productionOrder.id)}/cart`,
          { reference_code: reference_code },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.timeEnd("linkCartRequest");
        if (response.status === 200) {
          setSelectedProductionOrder((prevOrder) =>
            prevOrder ? { ...prevOrder, cart: reference_code } : null
          );

          console.time("fetchProductionOrders");
          fetchProductionOrders(startDate, endDate);
          console.timeEnd("fetchProductionOrders");

          console.log("Selected PO:", selectedProductionOrder);
        } else {
          console.error("Error al actualizar el carrito");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    [startDate, endDate, selectedProductionOrder]
  );

  const unlinkCart = useCallback(
    async (productionOrder, cart) => {
      const reference_code = cart;
      try {
        const accessToken = localStorage.getItem("access_token");
        console.log("Desvinculando carrito:", reference_code);
        console.time("unlinkCartRequest");
        const response = await axios.put(
          config.url +
            `/production_orders/${encodeURIComponent(
              productionOrder.id
            )}/uncart`,
          { reference_code: reference_code },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.timeEnd("unlinkCartRequest");
        if (response.status === 200) {
          setSelectedProductionOrder((prevOrder) =>
            prevOrder ? { ...prevOrder, cart: null } : null
          );

          console.time("fetchProductionOrders");
          fetchProductionOrders(startDate, endDate);
          console.timeEnd("fetchProductionOrders");

          console.log("Selected PO:", selectedProductionOrder);
        } else {
          console.error("Error al actualizar el carrito");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    [startDate, endDate, selectedProductionOrder]
  );

  const updateStatus = useCallback(
    async (productionOrder, status) => {
      try {
        const accessToken = localStorage.getItem("access_token");
        console.time("updateStatusRequest");
        const response = await axios.put(
          config.url +
            `/production_orders/${encodeURIComponent(
              productionOrder.id
            )}/status`,
          { status: status.id },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.timeEnd("updateStatusRequest");
        if (response.status === 200) {
          setSelectedProductionOrder((prevOrder) =>
            prevOrder
              ? {
                  ...prevOrder,
                  status: status.name,
                  status_color: status.color,
                }
              : null
          );

          if (status.name === "Finalizada") {
            console.time("handlePOUnsubscription");
            await handlePOUnsubscription(productionOrder.id);
            console.timeEnd("handlePOUnsubscription");
          }

          console.time("fetchProductionOrders");
          fetchProductionOrders(startDate, endDate);
          console.timeEnd("fetchProductionOrders");
        } else {
          console.error("Error al actualizar el estado");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [startDate, endDate]
  );

  const handlePOPicking = useCallback(async (productionOrderId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.time("handlePOPickingRequest");
      const response = await axios.post(
        config.url +
          `/production_orders/${encodeURIComponent(
            productionOrderId
          )}/trigger_picking`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.timeEnd("handlePOPickingRequest");
      if (response.status === 200 || response.status === 201) {
        console.log("Picking iniciado correctamente");
        alert("Picking iniciado correctamente");
      } else {
        console.error("Error al iniciar el picking");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("Error al realizar picking");
        alert("No se pudo iniciar el picking de forma correcta");
      }
      console.error("Error:", error);
    }

    fetchPickingStatus(productionOrderId);
  }, []);

  const handleErrorResponse = (error) => {
    if (error.response && error.response.status > 400) {
      console.log("No autorizado");
      handleLogout();
    }
    console.error("Error:", error);
  };

  const handleChange = (event) => {
    setSearchLine(event.target.value);
  };

  const handleAddCart = (cart) => {
    if (!selectedCarts.some((c) => c.id === cart.id)) {
      setSelectedCarts([...selectedCarts, cart]);
      updateCartsOnServer([...selectedCarts, cart]);
      setSelectedProductionOrder((prevOrder) =>
        prevOrder ? { ...prevOrder, carts: [...selectedCarts, cart] } : null
      );
    }
  };

  const handleRemoveCart = (cart) => {
    const updatedCarts = selectedCarts.filter((c) => c.id !== cart.id);
    setSelectedProductionOrder((prevOrder) =>
      prevOrder ? { ...prevOrder, carts: updatedCarts } : null
    );
    setSelectedCarts(updatedCarts);
    updateCartsOnServer(updatedCarts);
  };

  const updateCartsOnServer = async (updatedCarts) => {
    if (!selectedProductionOrder) return;
    try {
      console.log("Actualizando carros", updatedCarts);
      await axios.put(
        `${config.url}/production_orders/${encodeURIComponent(
          selectedProductionOrder.id
        )}/carts`,
        { cart_ids: updatedCarts.map((cart) => cart.id) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      console.log(`Carros actualizados correctamente ${updatedCarts}`);
      fetchAntennaForCarts(updatedCarts);
    } catch (error) {
      console.error("Error al actualizar carros", error);
    }
  };
  return (
    <Box>
      <Dialog open={confirmationDialogOpen} onClose={handleCancelSubscription}>
        <DialogTitle>{t("confirm_change")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!subscribedPOId &&
              t("are_you_sure_you_want_to_subscribe_to_production_order")}
            {subscribedPOId &&
              t("are_you_sure_you_want_to_unsubscribe_to_production_order")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSubscription} color="primary">
            {t("no")}
          </Button>
          <Button onClick={handleConfirmSubscription} color="primary" autoFocus>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={statusDialogOpen} onClose={handleCancelStatusChange}>
        <DialogTitle>{t("confirm_change")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!subscribedPOId &&
              t("are_you_sure_you_want_to_change_status_to_production_order")}
            {subscribedPOId &&
              t("are_you_sure_you_want_to_change_status_to_production_order")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelStatusChange} color="primary">
            {t("no")}
          </Button>
          <Button onClick={handleConfirmStatusChange} color="primary" autoFocus>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h4" className="custom-h4">
        {selectedProductionOrder
          ? `${t("production_order")}: ${selectedProductionOrder.id}`
          : t("production_orders")}

        {selectedProductionOrder &&
          selectedProductionOrder.parent_id &&
          ` ${t("hija de")} ${selectedProductionOrder.parent_id}`}
      </Typography>
      {!selectedProductionOrder && (
        <>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            locale={i18n.language}
          >
            {console.log("Lang:", i18n.language)}
            <Box display="flex" justifyContent="space-between" mb={2}>
              <DatePicker
                label={t("first_initial_date")}
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(newValue) => refreshStartDateData(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label={t("last_initial_date")}
                format="DD/MM/YYYY"
                value={endDate}
                onChange={(newValue) => refreshEndDateData(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
        </>
      )}

      {selectedProductionOrder && (
        <>
          <Typography variant="h5">{t("status")}</Typography>
          <Box
            style={{
              backgroundColor: selectedProductionOrder.status_color,
              width: "100%",
              justifyContent: "center",
            }}
          >
            {role === "Operator" || role === "Admin" ? (
              <TextField
                style={{
                  width: "100%",
                  justifyContent: "center",
                  color:
                    selectedProductionOrder.status_color &&
                    ["green", "red", "blue"].includes(
                      selectedProductionOrder.status_color.toLowerCase()
                    )
                      ? "white"
                      : "black",
                  fontWeight:
                    selectedProductionOrder.status_color &&
                    ["green", "red", "blue"].includes(
                      selectedProductionOrder.status_color.toLowerCase()
                    )
                      ? "bold"
                      : "normal",
                }}
                value={selectedProductionOrder.status}
              />
            ) : (
              <Select
                style={{
                  width: "100%",
                  justifyContent: "center",
                  color:
                    selectedProductionOrder.status_color &&
                    ["green", "red", "blue"].includes(
                      selectedProductionOrder.status_color.toLowerCase()
                    )
                      ? "white"
                      : "black",
                  fontWeight:
                    selectedProductionOrder.status_color &&
                    ["green", "red", "blue"].includes(
                      selectedProductionOrder.status_color.toLowerCase()
                    )
                      ? "bold"
                      : "normal",
                }}
                value={selectedProductionOrder.status}
                onChange={handleStatusChangeRequest}
              >
                {statuses.map((status) => (
                  <MenuItem key={status.id} value={status.name}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>

          <Box>
            {selectedProductionOrder && (
              <>
                <Typography variant="h5">{t("Carros")}</Typography>
                <FormControl fullWidth>
                  <InputLabel>{t("Agregar Carro")}</InputLabel>
                  <Select
                    onChange={(e) =>
                      handleAddCart(carts.find((c) => c.id === e.target.value))
                    }
                  >
                    {carts.map((cart) => (
                      <MenuItem key={cart.id} value={cart.id}>
                        {cart.reference_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box>
                  {selectedCarts.map((cart) => (
                    <Box key={cart.id} display="flex" alignItems="center">
                      <Typography>{cart.reference_code}</Typography>
                      <TextField
                        fullWidth
                        value={
                          cartAntennas[cart.id] || t("No hay antena asignada")
                        }
                        InputProps={{ readOnly: true }}
                      />
                      <IconButton onClick={() => handleRemoveCart(cart)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ p: 3 }}>
                  {/* Le pasamos el parentId al componente Children */}
                  <ChildrenCartsByParentId
                    parentId={selectedProductionOrder.id}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box>
            {console.log("Picking status:", pickingStatus.description)}
            <Typography variant="h5">{t("Estado de picking")}</Typography>
            <TextField value={pickingStatus.description} />
            <Button
              onClick={() => handlePOPicking(selectedProductionOrder.id)}
              variant="contained"
              color="primary"
            >
              {t("Iniciar Picking")}
            </Button>
          </Box>
        </>
      )}
      {selectedProductionOrder && role === "Operator" && (
        <Box>
          <Typography variant="h5">{t("subscribed")}:</Typography>
          <IconButton onClick={(event) => event.stopPropagation()}>
            <Checkbox
              checked={subscribedPOId === selectedProductionOrder.id}
              onChange={handleCheckBoxChange(selectedProductionOrder.id)}
              inputProps={{ "aria-label": "controlled" }}
              onClick={(event) => event.stopPropagation()}
            />
          </IconButton>
        </Box>
      )}

      {!selectedProductionOrder && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label={t("filter_by_bathroom_id")}
            variant="outlined"
            value={filter}
            onChange={handleFilterChange}
            sx={{ mb: 2, width: "100%", maxWidth: "500px" }}
          />
          <FormControl
            variant="outlined"
            sx={{ mb: 2, width: "100%", maxWidth: "500px" }}
          >
            <InputLabel>{t("filter_by_status")}</InputLabel>
            <Select
              label={t("filter_by_status")}
              value={statusFilter}
              onChange={(event) => setStatusFilter(event.target.value)}
            >
              <MenuItem key="-1" value="">
                {t("all_statuses")}
              </MenuItem>
              <MenuItem key="0" value="En proceso y En espera">
                {t("En proceso y En espera")}
              </MenuItem>
              {statuses.map((status) => (
                <MenuItem key={status.id} value={status.name}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>{t("select_line")}</InputLabel>
            <Select name="line_id" value={searchLine} onChange={handleChange}>
              {lines.map((line) => (
                <MenuItem key={line.id} value={line.id}>
                  {line.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {selectedProductionOrder && (
        <>
          <Typography variant="h4" className="custom-h4">
            {t("materials")}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("material_id")}</TableCell>
                  <TableCell>{t("description")}</TableCell>
                  <TableCell>{t("reference")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("unit_of_measurement")}</TableCell>
                  <TableCell>{t("material_lot_number")}</TableCell>
                  <TableCell>{t("carbon_footprint_coefficient")}</TableCell>
                  <TableCell>{t("station_type")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPOMaterials.map((material) => (
                  <TableRow key={material.id}>
                    <TableCell>{material.id}</TableCell>
                    <TableCell>{material.description}</TableCell>
                    <TableCell>{material.reference}</TableCell>
                    <TableCell>{material.quantity}</TableCell>
                    <TableCell>{material.unit_of_measurement}</TableCell>
                    <TableCell>{material.lot_number}</TableCell>
                    <TableCell>
                      {material.carbon_footprint_coefficient}
                    </TableCell>
                    <TableCell>{material.station_type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 4 }}>
            {semiElaboratesLoading && (
              <Typography variant="body1">
                {t("loading_semi_elaborates")}...
              </Typography>
            )}
            {semiElaboratesError && (
              <Typography variant="body1" color="error">
                {semiElaboratesError}
              </Typography>
            )}
            {!semiElaboratesLoading &&
              !semiElaboratesError &&
              semiElaborates.length > 0 && (
                <SemiElaborates semiElaborates={semiElaborates} />
              )}
            {!semiElaboratesLoading &&
              !semiElaboratesError &&
              semiElaborates.length === 0 && (
                <Typography variant="body1">
                  {t("no_semi_elaborates_available")}.
                </Typography>
              )}
          </Box>
        </>
      )}
      {/* **Nueva sección para Estadísticas con Carga y Manejo de Errores** */}
      {selectedProductionOrder && (
        <>
          {statsLoading && (
            <Typography variant="body1">
              {t("loading_statistics")}...
            </Typography>
          )}
          {statsError && (
            <Typography variant="body1" color="error">
              {statsError}
            </Typography>
          )}
          {!statsLoading && !statsError && productionOrderStatistics && (
            <StationStats statistics={productionOrderStatistics} />
          )}
          {!statsLoading && !statsError && !productionOrderStatistics && (
            <Typography variant="h4" className="custom-h4">
              {t("no_statistics_available")}.
            </Typography>
          )}
        </>
      )}

      {filteredProductionOrders.length > 0 && (
        <>
          <Box sx={{ mt: 4 }} />
          {!selectedProductionOrder && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#f5f5f5", // Fondo gris claro
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Efecto de sombra sutil
                  }}
                >
                  <TableRow>
                    <TableCell>{t("order_id")}</TableCell>
                    <TableCell>{t("parent_id")}</TableCell>
                    <TableCell>{t("expiration_date")}</TableCell>
                    <TableCell>{t("description")}</TableCell>
                    <TableCell>{t("start_datetime")}</TableCell>
                    <TableCell>{t("end_datetime")}</TableCell>
                    <TableCell>{t("type")}</TableCell>
                    <TableCell>{t("planned_line")}</TableCell>
                    <TableCell>{t("current_line")}</TableCell>
                    <TableCell>{t("current_station")}</TableCell>
                    {(role === "Operator" || role === "StationManagement") && (
                      <TableCell>{t("subscribed")}</TableCell>
                    )}
                    <TableCell>{t("trigger_picking")}</TableCell>
                    <TableCell>{t("incidents")}</TableCell>
                    <TableCell>{t("status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProductionOrders.map((po) => (
                    <React.Fragment key={po.id}>
                      <TableRow onClick={() => handleSelection(po)}>
                        <TableCell>{po.id}</TableCell>
                        <TableCell>{po.parent_id}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.expiration_date))}
                        </TableCell>

                        <TableCell>{po.description}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.start_datetime))}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.end_datetime))}
                        </TableCell>
                        <TableCell>{po.type}</TableCell>
                        <TableCell>{po.planned_line}</TableCell>
                        <TableCell>{po.current_line}</TableCell>
                        <TableCell>{po.current_station}</TableCell>
                        {(role === "Operator" ||
                          role === "StationManagement") && (
                          <TableCell>
                            <IconButton
                              onClick={(event) => event.stopPropagation()}
                            >
                              <Checkbox
                                checked={subscribedPOId === po.id}
                                onChange={handleCheckBoxChange(po.id)}
                                inputProps={{ "aria-label": "controlled" }}
                                onClick={(event) => event.stopPropagation()}
                              />
                            </IconButton>
                          </TableCell>
                        )}
                        <TableCell>
                          {
                            /**(po.parent_id === null) | (po.parent_id === "") ? (**/
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                handlePOPicking(po.id);
                              }}
                            >
                              {t("start")}
                            </Button>
                            /**) : (
                              t("use_button_from_parent")
                            )**/
                          }
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor:
                              po.incident_color && po.incident_color,
                            color:
                              po.incident_color &&
                              ["red", "blue"].includes(
                                po.incident_color.toLowerCase()
                              )
                                ? "white"
                                : "black",
                            fontSize: "0.7rem",
                            fontWeight:
                              po.incident_color &&
                              ["red", "blue"].includes(
                                po.incident_color.toLowerCase()
                              )
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {po.incidents &&
                            Object.entries(po.incidents)
                              .filter(([key, value]) => value.endsWith("Open"))
                              .map(([key, value]) => (
                                <div key={key}>
                                  {key}: {value.replace(/Open$/, "")}
                                </div>
                              ))}
                        </TableCell>

                        <TableCell
                          style={{
                            backgroundColor: po.status_color && po.status_color,
                            color:
                              po.status_color &&
                              ["red", "blue", "green"].includes(
                                po.status_color.toLowerCase()
                              )
                                ? "white"
                                : "black",
                            fontWeight: [
                              po.status_color && "red",
                              "blue",
                              "green",
                            ].includes(
                              po.status_color && po.status_color.toLowerCase()
                            )
                              ? "bold"
                              : "normal",
                          }}
                        >
                          {po.status}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
            <Typography sx={{ ml: 2 }}>
              {t("page")} {page} {t("of")} {totalPages}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProductionOrders;
