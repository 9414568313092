import React, { useState, useEffect } from "react";
import axios from "axios";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importar la localización en español
import "dayjs/locale/en"; // Importar la localización en inglés
import "dayjs/locale/fr"; // Importar la localización en francés
import "dayjs/locale/de"; // Importar la localización en alemán
import "dayjs/locale/it"; // Importar la localización en italiano
import "dayjs/locale/zh"; // Importar la localización en chino
import { useTranslation } from "react-i18next";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DownloadIcon from "@mui/icons-material/Download";
import TimelineIcon from "@mui/icons-material/Timeline";
import BuildIcon from "@mui/icons-material/Build";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import config from "./config.js";
import es from "date-fns/locale/es";
import DefectRateChart from "./DefectRateChart.jsx";

const API_BASE_URL = config.url;

const StatCard = ({ title, value, color, icon: Icon, description }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: "100%",
        p: 3,
        minHeight: 140,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: color,
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: theme.shadows[2],
            mr: 2,
          }}
        >
          <Icon sx={{ color: "white", fontSize: 24 }} />
        </Box>
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
      </Box>

      <Box sx={{ pl: 7 }}>
        <Typography
          variant="h3"
          sx={{
            color: color,
            fontWeight: "bold",
            fontSize: "2.5rem",
            lineHeight: 1,
            mb: 0.5,
          }}
        >
          {value}
        </Typography>
        {description && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ lineHeight: 1.2 }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Card>
  );
};

const ChartContainer = ({ title, children }) => (
  <Paper
    sx={{
      p: 3,
      height: "100%",
      mb: 6,
    }}
  >
    <Typography
      variant="h6"
      sx={{
        mb: 4,
        fontWeight: "medium",
      }}
    >
      {title}
    </Typography>
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .recharts-wrapper": {
          mb: 2,
        },
      }}
    >
      {children}
    </Box>
  </Paper>
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 4 }}>{children}</Box>}
    </div>
  );
}

export default function ProductionDashboard() {
  const theme = useTheme();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  });
  const [dashboardData, setDashboardData] = useState({
    productionStatus: {},
    cycleTimes: [],
    defectRate: [],
    stopTime: [],
    materialConsumption: [],
    operatorHours: [],
    stationTimes: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState("all");
  const [tabValue, setTabValue] = useState(0);

  const fetchData = async (endpoint) => {
    const token = localStorage.getItem("access_token");
    const response = await fetch(API_BASE_URL + endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("access_token");
        window.location.href = "/login";
        return;
      }
      throw new Error(`HTTP error ${response.status}`);
    }
    return await response.json();
  };

  const handleSearch = async (stationId) => {
    try {
      setLoading(true);
      setError(null);
      const formattedStartDate = dateRange.startDate
        .toISOString()
        .split("T")[0];
      const formattedEndDate = dateRange.endDate.toISOString().split("T")[0];
      const stationQuery =
        stationId && stationId !== "all" ? `&station_id=${stationId}` : "";

      const [
        productionStatus,
        cycleTimes,
        defectRate,
        stopTime,
        materialConsumption,
        operatorHours,
        stationTimes,
      ] = await Promise.all([
        fetchData(
          `/dashboard/production-status?start_date=${formattedStartDate}&end_date=${formattedEndDate}${stationQuery}`
        ),
        fetchData(
          `/dashboard/cycle-times?start_date=${formattedStartDate}&end_date=${formattedEndDate}${stationQuery}`
        ),
        fetchData(
          `/dashboard/defect-rate?start_date=${formattedStartDate}&end_date=${formattedEndDate}${stationQuery}`
        ),
        fetchData(
          `/dashboard/stop-time?start_date=${formattedStartDate}&end_date=${formattedEndDate}${stationQuery}`
        ),
        fetchData(
          `/dashboard/material-consumption?start_date=${formattedStartDate}&end_date=${formattedEndDate}${stationQuery}`
        ),
        fetchData(
          `/dashboard/operator-hours?start_date=${formattedStartDate}&end_date=${formattedEndDate}${stationQuery}`
        ),
        fetchData(
          `/dashboard/station-times?start_date=${formattedStartDate}&end_date=${formattedEndDate}${stationQuery}`
        ),
      ]);

      setDashboardData({
        productionStatus,
        cycleTimes,
        defectRate,
        stopTime,
        materialConsumption,
        operatorHours,
        stationTimes,
      });
    } catch (err) {
      setError("Error cargando datos del dashboard: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = async (endpoint, filename) => {
    try {
      const token = localStorage.getItem("access_token");
      const formattedStartDate = dateRange.startDate
        .toISOString()
        .split("T")[0];
      console.log("formattedStartDate", formattedStartDate);
      const formattedEndDate = dateRange.endDate.toISOString().split("T")[0];
      console.log("formattedEndDate", formattedEndDate);
      const response = await fetch(
        API_BASE_URL +
          endpoint +
          `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("access_token");
          window.location.href = "/login";
          return;
        }
        throw new Error(`HTTP error ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading report:", error);
      setError("Error al descargar el reporte");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      window.location.href = "/login";
      return;
    }

    const fetchStations = async () => {
      try {
        const response = await fetchData("/stations");
        setStations(response);
      } catch (error) {
        console.error("Error fetching stations:", error);
        setError("Error cargando estaciones");
      }
    };

    fetchStations();
    handleSearch("all");
  }, []);

  useEffect(() => {
    handleSearch(selectedStation);
  }, [selectedStation, dateRange]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }
  const downloadProductionOrderHistory = async () => {
    try {
      const formattedStartDate = dateRange.startDate
        .toISOString()
        .split("T")[0];
      const formattedEndDate = dateRange.endDate.toISOString().split("T")[0];

      if (!formattedStartDate || !formattedEndDate) {
        alert("Por favor, seleccione un rango de fechas.");
        return;
      }

      const response = await axios.get(
        config.url + "/operator-production-order-subscription-history",
        {
          params: {
            startDate: formattedStartDate, // Removed unnecessary format function
            endDate: formattedEndDate, // Removed unnecessary format function
          },
          responseType: "blob",
          validateStatus: (status) => {
            return status < 500;
          },
        }
      );

      if (response.status === 400) {
        console.log(response.data);
        alert(response.data.message || "Ha ocurrido un error.");
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "operator_production_order_subscription_history.xlsx"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link after clicking
    } catch (error) {
      console.error(
        "Error downloading production order subscription history",
        error
      );
      alert(
        "Error al descargar el historial de suscripciones de órdenes de producción."
      );
    }
  };

  const downloadStationSubscriptionHistory = async () => {
    try {
      const formattedStartDate = dateRange.startDate
        .toISOString()
        .split("T")[0];
      const formattedEndDate = dateRange.endDate.toISOString().split("T")[0];

      if (!formattedStartDate || !formattedEndDate) {
        alert("Por favor, seleccione un rango de fechas.");
        return;
      }

      const response = await axios.get(
        config.url + "/operator-station-subscription-history",
        {
          params: {
            startDate: formattedStartDate, // Removed unnecessary format function
            endDate: formattedEndDate, // Removed unnecessary format function
          },
          responseType: "blob",
          validateStatus: (status) => {
            return status < 500;
          },
        }
      );

      if (response.status === 400) {
        console.log(response.data);
        alert(response.data.message || "Ha ocurrido un error.");
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "operator_station_subscription_history.xlsx"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link after clicking
    } catch (error) {
      console.error("Error downloading station subscription history", error);
      alert("Error al descargar el historial de suscripciones de estaciones.");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Box sx={{ p: 4, maxWidth: "1800px", margin: "0 auto" }}>
        {/* Header y Filtros */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold" }}>
              Dashboard de Producción
            </Typography>
            <Typography variant="h10" sx={{ mb: 2 }}>
              El rango filtra por la fecha planificada de inicio.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4}>
                  <DatePicker
                    label="Fecha de Inicio"
                    value={dateRange.startDate}
                    onChange={(newValue) =>
                      setDateRange((prev) => ({ ...prev, startDate: newValue }))
                    }
                    fullWidth
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePicker
                    label="Fecha de Fin"
                    value={dateRange.endDate}
                    onChange={(newValue) =>
                      setDateRange((prev) => ({ ...prev, endDate: newValue }))
                    }
                    fullWidth
                    sx={{ width: "100%" }}
                  />
                </Grid>
                {/*<Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Estación</InputLabel>
                                        <Select
                                            value={selectedStation}
                                            label="Estación"
                                            onChange={(e) => setSelectedStation(e.target.value)}
                                        >
                                            <MenuItem value="all">Todas las Estaciones</MenuItem>
                                            {stations.map((station) => (
                                                <MenuItem key={station.id} value={station.id}>
                                                    {station.name} ({station.code})
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>*/}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* Tarjetas de Estadísticas */}
        <Grid
          container
          spacing={4}
          sx={{
            mb: 8,
            px: 2,
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            alignItems: "center",
            minHeight: "250px" /* Adjust this value */,
          }}
        >
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            {" "}
            {/* Adjust breakpoints as needed */}
            <StatCard
              title="Órdenes en espera"
              value={dashboardData.productionStatus.waiting || 0}
              color={theme.palette.primary.main}
              icon={TimelineIcon}
              description="En el periodo selecionado"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            {" "}
            {/* Adjust breakpoints as needed */}
            <StatCard
              title="Órdenes en Curso"
              value={dashboardData.productionStatus.in_progress || 0}
              color={theme.palette.primary.main}
              icon={TimelineIcon}
              description="En el periodo selecionado"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <StatCard
              title="Órdenes Finalizadas"
              value={dashboardData.productionStatus.completed || 0}
              color={theme.palette.success.main}
              icon={BuildIcon}
              description="En el periodo selecionado"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <StatCard
              title="Órdenes con incidentes"
              value={dashboardData.productionStatus.with_incidents || 0}
              color="#FFA500"
              icon={ErrorOutlineIcon}
              description="En el periodo selecionado"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <StatCard
              title="Órdenes Canceladas"
              value={dashboardData.productionStatus.cancelled || 0}
              color={theme.palette.error.main}
              icon={ErrorOutlineIcon}
              description="En el periodo selecionado"
            />
          </Grid>
        </Grid>

        {/* Tabs y Gráficos */}
        <Paper sx={{ mb: 4 }}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab
              label="Tiempos en progreso"
              icon={<BarChartIcon />}
              iconPosition="start"
            />
            <Tab
              label="Tendencias"
              icon={<BarChartIcon />}
              iconPosition="start"
            />
            <Tab
              label="Producción"
              icon={<TimelineIcon />}
              iconPosition="start"
            />
            <Tab label="Operarios" icon={<PeopleIcon />} iconPosition="start" />
            <Tab
              label="% Defectos"
              icon={<BarChartIcon />}
              iconPosition="start"
            />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <ChartContainer title="Tiempos por Estación de Órdenes en progreso">
                  <ResponsiveContainer>
                    <BarChart data={dashboardData.stationTimes}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="station" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="real_time"
                        fill={theme.palette.primary.main}
                        name="Tiempo Real (min)"
                      />
                      <Bar
                        dataKey="expected_time"
                        fill={theme.palette.warning.main}
                        name="Tiempo Esperado (min)"
                      />
                      <Bar
                        dataKey="operator_time"
                        fill={theme.palette.success.main}
                        name="Tiempo Operador (min)"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </ChartContainer>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <ChartContainer title="Tiempos de Ciclo por Estación de órdenes en progreso">
                    <ResponsiveContainer>
                      <BarChart data={dashboardData.cycleTimes}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="station" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="avg_time"
                          fill={theme.palette.primary.main}
                          name="Tiempo Promedio (min)"
                        />
                        <Bar
                          dataKey="max_time"
                          fill={theme.palette.warning.main}
                          name="Tiempo Máximo (min)"
                        />
                        <Bar
                          dataKey="min_time"
                          fill={theme.palette.success.main}
                          name="Tiempo Mínimo (min)"
                        />
                        <Bar
                          dataKey="trend"
                          fill={theme.palette.info.main}
                          name="Tiempo Tendencia 30 días previos (min)"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </ChartContainer>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <ChartContainer title="Tiempo de Parada">
                  <ResponsiveContainer>
                    <BarChart data={dashboardData.stopTime}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="station" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="stop_time"
                        fill={theme.palette.error.main}
                        name="Tiempo de Parada (min)"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </ChartContainer>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <ChartContainer title="Horas de Trabajo por Operario">
                  <ResponsiveContainer>
                    <BarChart data={dashboardData.operatorHours}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="operator_name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="hours_worked"
                        fill={theme.palette.success.main}
                        name="Horas Trabajadas"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </ChartContainer>
              </Grid>
            </Grid>
          </TabPanel>
          <DefectRateChart tabValue={tabValue} dashboardData={dashboardData} />
        </Paper>

        {/* Botones de Descarga */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2.4}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={() =>
                downloadReport(
                  "/reports/production",
                  `bathroom_production_report_${
                    dateRange.startDate.toISOString().split("T")[0]
                  }_${dateRange.endDate.toISOString().split("T")[0]}.xlsx`
                )
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                "&:hover": {
                  bgcolor: theme.palette.secondary.dark,
                },
                height: "48px",
              }}
            >
              Reporte de Producción de Baños
            </Button>
          </Grid>
          {/*<Grid item xs={12} sm={6} md={2.4}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={() =>
                downloadReport("/reports/efficiency", "efficiency_report.xlsx")
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                "&:hover": {
                  bgcolor: theme.palette.secondary.dark,
                },
                height: "48px",
              }}
            >
              Reporte de Eficiencia
            </Button>
          </Grid>*/}
          <Grid item xs={12} sm={6} md={2.4}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={() =>
                downloadReport(
                  "/reports/po-disaggregated",
                  `po-disaggregated_report_${
                    dateRange.startDate.toISOString().split("T")[0]
                  }_${dateRange.endDate.toISOString().split("T")[0]}.xlsx`
                )
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                "&:hover": {
                  bgcolor: theme.palette.secondary.dark,
                },
                height: "48px",
              }}
            >
              Reporte de Órdenes desagregadas
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={() =>
                downloadReport(
                  "/reports/material-consumption",
                  `material_consumption_report_${
                    dateRange.startDate.toISOString().split("T")[0]
                  }_${dateRange.endDate.toISOString().split("T")[0]}.xlsx`
                )
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                "&:hover": {
                  bgcolor: theme.palette.secondary.dark,
                },
                height: "48px",
              }}
            >
              Reporte de Materiales
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={() =>
                downloadReport(
                  "/reports/operarios",
                  `operators_report_${
                    dateRange.startDate.toISOString().split("T")[0]
                  }_${dateRange.endDate.toISOString().split("T")[0]}.xlsx`
                )
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                "&:hover": {
                  bgcolor: theme.palette.secondary.dark,
                },
                height: "48px",
              }}
            >
              Reporte de Operarios
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={() =>
                downloadReport(
                  "/reports/incidents",
                  `incidents_report_${
                    dateRange.startDate.toISOString().split("T")[0]
                  }_${dateRange.endDate.toISOString().split("T")[0]}.xlsx`
                )
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                "&:hover": {
                  bgcolor: theme.palette.secondary.dark,
                },
                height: "48px",
              }}
            >
              Reporte de Incidentes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}
