//This files contains the StationManagement component which is the main component for the stationmanagement page. It contains the StationManagementHeader, StationManagementSidebar and StationManagementContent components.
//StationManagementHeader contains the header of the stationmanagement page.
//StationManagementSidebar contains the sidebar of the stationmanagement page.
//StationManagementContent contains the content of the stationmanagement page.
import React, { useEffect, useState } from "react";
import config from "./config.js";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import { Dashboard, Settings, ExitToApp } from "@mui/icons-material";
import StationManagementHeader from "./StationManagementHeader";
import StationManagementSidebar from "./StationManagementSidebar";
import StationManagementContent from "./StationManagementContent";
import { handleLogout } from "./Logout";
import axios from "axios";

const StationManagement = () => {
  const [open, setOpen] = useState(true);
  const [selectedSection, setSelectedSection] = useState("Dashboard");
  const [forceRender, setForceRender] = useState(0);
  const [subscribedPOId, setSubscribedPOId] = useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const fetchUserData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const userName = localStorage.getItem("username");
      const response = await axios.get(config.url + `/user/${userName}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setSubscribedPOId(response.data.production_order_id);
      localStorage.setItem("station_id", response.data.station_id);
    } catch (error) {
      console.error("Error fetching user data:", error);
      handleLogout();
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleDrawerClose = () => {
    //setOpen(false);
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    console.log("Selected section:", section);
    console.log("Force render:", forceRender);
    forceRender === 0 ? setForceRender(1) : setForceRender(0);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <StationManagementHeader
        open={open}
        handleDrawerOpen={handleDrawerOpen}
      />
      <StationManagementSidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        onSectionChange={handleSectionChange}
      />
      {
        <StationManagementContent
          key={forceRender}
          selectedSection={selectedSection}
          subscribedPOId={subscribedPOId}
          setSubscribedPOId={setSubscribedPOId}
        />
      }
    </Box>
  );
};

export default StationManagement;
