//This files contains the Operator component which is the main component for the operator page. It contains the OperatorHeader, OperatorSidebar and OperatorContent components.
//OperatorHeader contains the header of the operator page.
//OperatorSidebar contains the sidebar of the operator page.
//OperatorContent contains the content of the operator page.
import React, { useEffect, useState } from "react";
import config from "./config.js";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import { Dashboard, Settings, ExitToApp } from "@mui/icons-material";
import OperatorHeader from "./OperatorHeader";
import OperatorSidebar from "./OperatorSidebar";
import OperatorContent from "./OperatorContent";
import { handleLogout } from "./Logout";
import axios from "axios";

const Operator = () => {
  const [open, setOpen] = useState(true);
  const [selectedSection, setSelectedSection] = useState("Dashboard");
  const [forceRender, setForceRender] = useState(0);
  const [subscribedPOId, setSubscribedPOId] = useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const fetchUserData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const userName = localStorage.getItem("username");
      const response = await axios.get(
        config.url+`/user/${userName}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      setSubscribedPOId(response.data.production_order_id);
    } catch (error) {
      console.error("Error fetching user data:", error);
      handleLogout();
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleDrawerClose = () => {
    //setOpen(false);
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    console.log("Selected section:", section);
    console.log("Force render:", forceRender);
    forceRender === 0 ? setForceRender(1) : setForceRender(0);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <OperatorHeader open={open} handleDrawerOpen={handleDrawerOpen} />
      <OperatorSidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        onSectionChange={handleSectionChange}
      />
      <OperatorContent
        key={forceRender}
        selectedSection={selectedSection}
        subscribedPOId={subscribedPOId}
        setSubscribedPOId={setSubscribedPOId}
      />
    </Box>
  );
};

export default Operator;
