// ChildrenCartsByParentId.jsx
import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { Box, CircularProgress, Paper, Typography, Grid } from "@mui/material";
import config from "./config";

function ChildrenCartsByParentId({ parentId }) {
  const [childrenCarts, setChildrenCarts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // 1. Función para obtener los children carts por parent_id
  const fetchChildrenCarts = useCallback(async () => {
    if (!parentId) return;

    setIsLoading(true);
    setError(null);

    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/carts/po-children-carts/${encodeURIComponent(parentId)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setChildrenCarts(response.data);
      } else {
        console.error("Error al obtener children carts");
        setChildrenCarts([]);
        setError("Error al obtener children carts.");
      }
    } catch (err) {
      console.error("Ocurrió un error al obtener children carts:", err);
      setChildrenCarts([]);
      setError("Ocurrió un error al obtener children carts.");
    } finally {
      setIsLoading(false);
    }
  }, [parentId]);

  // 2. Llamamos a la función cada vez que cambie el parentId
  useEffect(() => {
    fetchChildrenCarts();
  }, [parentId, fetchChildrenCarts]);

  // 3. Agrupar por children_order_id
  const groupedData = useMemo(() => {
    return childrenCarts.reduce((acc, item) => {
      const key = item.children_order_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }, [childrenCarts]);

  // 4. Render
  return (
    <Box>
      {/* Loader */}
      {isLoading && <CircularProgress />}

      {/* Error */}
      {error && (
        <Typography color="error" variant="body1" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {/* Si no hay error ni está cargando y tenemos datos, renderizamos el grid */}
      {!isLoading && !error && Object.keys(groupedData).length > 0 && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {Object.entries(groupedData).map(([orderId, cartsArray]) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={orderId}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Orden hija: {orderId}
                </Typography>
                {/* Listado de carts para este children_order_id */}
                {cartsArray.map((item, idx) => (
                  <Box key={idx} sx={{ mb: 2 }}>
                    <Typography variant="body2">
                      <strong>Carro:</strong> {item.cart_reference}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Antena:</strong> {item.antenna_name}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Si el array está vacío y no hay error, un mensaje opcional */}
      {!isLoading && !error && childrenCarts.length === 0 && (
        <Typography variant="body2">
          Órdenes hijas sin carros disponibles.
        </Typography>
      )}
    </Box>
  );
}

export default ChildrenCartsByParentId;
