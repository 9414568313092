//This file contains the AdminSidebar component which is the sidebar of the admin page. It contains the sidebar items for the admin page.
import React from "react";
import config from "./config.js";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import {
  Dashboard,
  Settings,
  ExitToApp,
  Info,
  Article,
  ReportProblem,
  Work,
  AddLocationAltSharp,
  ShoppingCart,
  SettingsInputAntennaSharp as SettingsInputAntennaSharpIcon,
  PeopleSharp,
  Warehouse,
  Schedule,
} from "@mui/icons-material";
import BarChartIcon from "@mui/icons-material/BarChart";
import AssistantIcon from "@mui/icons-material/Assistant";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";

const AdminSidebar = ({ open, handleDrawerClose, onSectionChange }) => {
  const { t } = useTranslation();
  return (
    <Drawer
      onClick={handleDrawerClose}
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <List>
        <ListItem>
          <Typography variant="h6" noWrap>
            {t("admin_dashboard")}
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="h6" noWrap></Typography>
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Dashboard")}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary={t("dashboard")} />
        </ListItem>
        {/*<ListItem button onClick={() => onSectionChange("IA")}>
          <ListItemIcon>
            <AssistantIcon />
          </ListItemIcon>
          <ListItemText primary={t("IA")} />
        </ListItem>*/}
        <ListItem button onClick={() => onSectionChange("Production Orders")}>
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText primary={t("production_orders")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Stations")}>
          <ListItemIcon>
            <AddLocationAltSharp />
          </ListItemIcon>
          <ListItemText primary={t("stations")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Users")}>
          <ListItemIcon>
            <PeopleSharp />
          </ListItemIcon>
          <ListItemText primary={t("users")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Antenna Manager")}>
          <ListItemIcon>
            <SettingsInputAntennaSharpIcon />
          </ListItemIcon>
          <ListItemText primary={t("antenna_manager")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("VerticalWarehouse")}>
          <ListItemIcon>
            <Warehouse />
          </ListItemIcon>
          <ListItemText primary={t("vertical_warehouse")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Carts")}>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary={t("carts")} />
        </ListItem>
        {/*<ListItem button onClick={() => onSectionChange("Graphics")}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary={t("graphics")} />
        </ListItem>
        */}
        <ListItem button onClick={() => onSectionChange("incidents")}>
          <ListItemIcon>
            <ReportProblem />
          </ListItemIcon>
          <ListItemText primary={t("incidents")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("WorkShifts")}>
          <ListItemIcon>
            <Schedule />
          </ListItemIcon>
          <ListItemText primary={t("WorkShifts")} />
        </ListItem>
        {/*<ListItem button onClick={() => onSectionChange("OEEDashboard")}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary={t("Stations OEE")} />
        </ListItem>*/}
        <ListItem button onClick={() => onSectionChange("settings")}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={t("settings")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => onSectionChange("About Us")}>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary={t("about_us")} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={t("logout")} />
        </ListItem>
        <ListItem>
          <img
            src="FC.png"
            alt="logo"
            style={{ width: "50%", height: "auto", margin: "auto" }}
          />
        </ListItem>
        <ListItem>
          {/*center text*/}
          <Typography variant="h6" noWrap style={{ margin: "auto" }}>
            {"DSA Grupo ®"}
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default AdminSidebar;
