import React from "react";
import { Box, Typography } from "@mui/material";

function Message({ message }) {
  const messageText = message?.text || "";
  
  return (
    <Box
      sx={{
        padding: 1.5,
        borderRadius: 2,
        maxWidth: '70%',
        marginLeft: message.sender === 'user' ? 'auto' : '0',
        backgroundColor: message.sender === 'user' ? '#dcf8c6' : 'transparent',
        border: message.sender === 'user' ? '1px solid #c8e6c9' : 'none'
      }}
    >
      <Typography>{messageText}</Typography>
    </Box>
  );
}

export default Message;