import React, { useState, useEffect, useRef } from "react";
import config from "./config.js";
import useComponentVisibility from "../hooks/useComponentVisibility";
import axios from "axios";
import { useTranslation } from "react-i18next";

import {
  TextField,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Edit,
  Delete,
  Warehouse,
  ArrowCircleDown as ArrowCircleDownIcon,
} from "@mui/icons-material";
import "./styles.css";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importar la localización en español
import "dayjs/locale/en"; // Importar la localización en inglés
import "dayjs/locale/fr"; // Importar la localización en francés
import "dayjs/locale/de"; // Importar la localización en alemán
import "dayjs/locale/it"; // Importar la localización en italiano
import "dayjs/locale/zh"; // Importar la localización en chino

const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short", // To include the GMT information
};

/*Manage storehouse kit data*/

const VerticalWarehouse = () => {
  const [kits, setKits] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentKit, setCurrentKit] = useState({
    id: null,
    station_id: "",
    description: "",
    date: "",
    status: "",
    production_order_id: "",
    user_id: "",
  });
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("En proceso y En espera");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [carts, setCarts] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(14, "day"));

  const [endDate, setEndDate] = useState(null);
  dayjs.locale(i18n.language);

  const [productionOrders, setProductionOrders] = useState([]);
  const [subProductionOrders, setSubProductionOrders] = useState([]);
  const [selectedProductionOrder, setSelectedProductionOrder] = useState(null);

  const [modulaKits, setModulaKits] = useState([]);
  const [modulaStatuses, setModulaStatuses] = useState({});
  const [modulaStatusColors, setModulaStatusColors] = useState({});
  const [modulaKit, setModulaKit] = useState("");
  const [modulaStatus, setModulaStatus] = useState(t("not_requested"));
  const [modulaStatusColor, setModulaStatusColor] = useState("green");
  const [isVisible, ref] = useComponentVisibility();
  const pollingRef = useRef({}); // To keep track of intervals for each command

  useEffect(() => {
    fetchKits();
  }, []);
  const fetchProductionOrders = async (startDate, endDate) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const url =
        localStorage.getItem("role") === "StationManagement"
          ? config.url + "/production_orders/station-management"
          : config.url + "/all_production_orders";

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
          endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
        },
      });
      if (response.status === 200) {
        console.log("Production orders:", response.data);

        // Sort the production orders by start_datetime from older to newer
        const sortedOrders = response.data.sort(
          (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime)
        );

        setProductionOrders(sortedOrders);
        console.log("Selected PO:", selectedProductionOrder);
      } else {
        console.error("Failed to fetch production orders");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  const handleSelection = (productionOrder) => {
    setSelectedProductionOrder(productionOrder);
  };

  const fetchKits = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/kits", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        setKits(response.data);
        console.log(response.data);
      } else {
        console.error("Failed to fetch kits");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleErrorResponse = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        handleLogout();
      } else {
        console.error(error.response.data);
      }
    } else {
      console.error(error);
    }
  };

  const handleOpen = (kit) => {
    setCurrentKit(kit);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.delete(config.url + "/kits/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        fetchKits();
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  const handleEdit = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
        config.url + "/kits/" + currentKit.id,
        currentKit,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        fetchKits();
        setOpen(false);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  const handleSubmitKit = async (kitId) => {
    if (kitId !== undefined && kitId !== null) {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axios.put(
          config.url + `/modula/${kitId}/V`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          pollVStatus(kitId);
        } else {
          console.error("Failed to submit modula kit");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    }
  };
  const pollVStatus = (kitId) => {
    const checkStatus = async () => {
      if (!isVisible) {
        clearInterval(pollingRef.current[kitId]);
        return;
      }

      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axios.get(
          config.url + `/modula/check_status/${kitId}/V`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const status = response.data.status;
        const statusColor = response.data.status_color;

        setModulaStatus((prevStatuses) => ({
          ...prevStatuses,
          [kitId]: status,
        }));

        setModulaStatusColor((prevColors) => ({
          ...prevColors,
          [kitId]: statusColor,
        }));

        if (status === "Completed") {
          clearInterval(pollingRef.current[kitId]);
        }
      } catch (error) {
        console.error("Error checking status", error);
        clearInterval(pollingRef.current[kitId]);
      }
    };

    checkStatus();
  };
  const handleRequestKit = async (kitId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
        config.url + `/modula/${kitId}/P`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        pollStatus(kitId);
      } else {
        console.error("Failed to request modula kit");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  const pollStatus = (kitId) => {
    const checkStatus = async () => {
      if (!isVisible) {
        clearInterval(pollingRef.current[kitId]);
        return;
      }
      if (kitId !== undefined && kitId !== null) {
        try {
          const accessToken = localStorage.getItem("access_token");
          const response = await axios.get(
            config.url + `/modula/check_status/${kitId}/P`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const status = response.data.status;
          const statusColor = response.data.status_color;

          setModulaStatuses((prevStatuses) => ({
            ...prevStatuses,
            [kitId]: status,
          }));

          setModulaStatusColors((prevColors) => ({
            ...prevColors,
            [kitId]: statusColor,
          }));

          if (status === "Completed") {
            clearInterval(pollingRef.current[kitId]);
          }
        } catch (error) {
          console.error("Error checking status", error);
          clearInterval(pollingRef.current[kitId]);
        }
      }
    };

    checkStatus();
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const filteredProductionOrders = productionOrders.filter(
    (po) =>
      (po.id.toString().includes(filter) ||
        (po.parent_id && po.parent_id.toString().includes(filter)) ||
        (po.type && po.type.toLowerCase().includes(filter.toLowerCase()))) &&
      (statusFilter === "" ||
        po.status === statusFilter ||
        (statusFilter === "En proceso y En espera" &&
          (po.status === "En proceso" || po.status === "En espera")))
  );

  const paginatedProductionOrders = filteredProductionOrders.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const totalPages = Math.ceil(filteredProductionOrders.length / rowsPerPage);

  const handlePOPicking = async (productionOrderId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        config.url + `/production_orders/${productionOrderId}/trigger_picking`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Picking triggered successfully");
      } else {
        console.error("Failed to trigger picking");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("vertical_warehouse_operations")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("ID")}</TableCell>
              <TableCell>{t("date")}</TableCell>
              <TableCell>{t("production_order")}</TableCell>
              <TableCell>{t("reference")}</TableCell>
              <TableCell>{t("op_type")}</TableCell>
              <TableCell>{t("status")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kits.map((kit) => (
              <TableRow key={kit.id}>
                <TableCell>{kit.id}</TableCell>
                <TableCell>
                  {" "}
                  {new Intl.DateTimeFormat(config.langTZ, options).format(
                    new Date(kit.date)
                  )}
                </TableCell>
                <TableCell>{kit.production_order_id}</TableCell>
                <TableCell>{kit.reference}</TableCell>
                <TableCell>{kit.op_type}</TableCell>
                <TableCell>{kit.status}</TableCell>

                <TableCell>
                  <IconButton onClick={() => handleDelete(kit.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Edit Kit")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Edit the kit")}</DialogContentText>
          <TextField
            label={t("Station")}
            value={currentKit.station_id}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, station_id: e.target.value })
            }
          />
          <TextField
            label={t("Description")}
            value={currentKit.description}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, description: e.target.value })
            }
          />
          <TextField
            label={t("Date")}
            value={currentKit.date}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, date: e.target.value })
            }
          />
          <TextField
            label={t("Status")}
            value={currentKit.status}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, status: e.target.value })
            }
          />
          <TextField
            label={t("Production Order ID")}
            value={currentKit.production_order_id}
            onChange={(e) =>
              setCurrentKit({
                ...currentKit,
                production_order_id: e.target.value,
              })
            }
          />
          <TextField
            label={t("User ID")}
            value={currentKit.user_id}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, user_id: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={handleEdit}>{t("Save")}</Button>
        </DialogActions>
      </Dialog>

      {filteredProductionOrders.length > 0 && (
        <>
          <Box sx={{ mt: 4 }} />

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
            <Typography sx={{ ml: 2 }}>
              {t("page")} {page} {t("of")} {totalPages}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default VerticalWarehouse;
