//This file contains the OperatorContent component which is the content of the operator page. It contains the content items for the operator page.
import React, { useEffect, useState } from "react";
import config from "./config.js";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Edit, Delete, Settings } from "@mui/icons-material";
import Logout from "./Logout";
import Tags from "./Tags";
import Floors from "./Floors";
import Stations from "./Stations";
import Carts from "./Carts";
import ProductionOrders from "./ProductionOrders";
import Materials from "./Materials";
import AboutUs from "./AboutUs";
import Incidents from "./Incidents";
import Documentation from "./Documentation";
import UserSettings from "./Settings";
import { useTranslation } from "react-i18next";
import "./styles.css";

const OperatorContent = ({
  selectedSection,
  subscribedPOId,
  setSubscribedPOId,
}) => {
  useEffect(() => {
    console.log("Subscribed PO Content:", subscribedPOId);
  }, []);
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
      <Typography variant="body1" className="custom-h4">
        {/*selectedSection.toLowerCase()*/}
      </Typography>
      {/*selectedSection === "Dashboard" && <Typography></Typography>*/}
      {selectedSection === "Tags" && <Tags />}
      {subscribedPOId && selectedSection === "Floors" && (
        <Floors subscribedPOId={subscribedPOId} />
      )}
      {selectedSection === "Stations" && <Stations />}
      {selectedSection === "Carts" && <Carts />}
      {selectedSection === "Production Orders" && (
        <ProductionOrders
          subscribedPOId={subscribedPOId}
          setSubscribedPOId={setSubscribedPOId}
        />
      )}
      {selectedSection === "Materials" && <Materials />}
      {/*selectedSection === "Settings" && <Typography></Typography>*/}
      {selectedSection === "About Us" && <AboutUs />}
      {selectedSection === "incidents" && <Incidents />}
      {selectedSection === "documents" && <Documentation />}
      {selectedSection === "settings" && <UserSettings />}
    </Box>
  );
};

export default OperatorContent;
