// SemiElaborates.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// This component receives an array of semi-elaborates and displays them in a table
const SemiElaborates = ({ semiElaborates }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" className="custom-h4">
        {t("semi_elaborates")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("semi_elaborate_id")}</TableCell>
              <TableCell>{t("production_order_id")}</TableCell>
              <TableCell>{t("quantity")}</TableCell>
              <TableCell>{t("station_type")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {semiElaborates.map((semiElaborate) => (
              <TableRow key={semiElaborate.id}>
                <TableCell>{semiElaborate.semi_elaborate_id}</TableCell>
                <TableCell>{semiElaborate.production_order_id}</TableCell>
                <TableCell>{semiElaborate.quantity}</TableCell>
                <TableCell>{semiElaborate.station_type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SemiElaborates;
