import React from "react";
import config from "./config.js";
import { useTranslation } from "react-i18next";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={false}
        onClick={(e) => changeLanguage("es")}
      >
        ES
      </Button>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={false}
        onClick={(e) => changeLanguage("en")}
      >
        EN
      </Button>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={false}
        onClick={(e) => changeLanguage("fr")}
      >
        FR
      </Button>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={false}
        onClick={(e) => changeLanguage("de")}
      >
        DE
      </Button>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={false}
        onClick={(e) => changeLanguage("zh")}
      >
        中文
      </Button>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={false}
        onClick={(e) => changeLanguage("it")}
      >
        IT
      </Button>
    </div>
  );
};

export default LanguageSwitcher;
