import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import { Grid, Box, Typography, Paper, useTheme } from "@mui/material";

const DefectRateChart = ({ tabValue, dashboardData }) => {
  const theme = useTheme();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`dashboard-tabpanel-${index}`}
        aria-labelledby={`dashboard-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ py: 4 }}>{children}</Box>}
      </div>
    );
  }

  const ChartContainer = ({ title, children }) => (
    <Paper
      sx={{
        p: 3,
        height: "100%",
        mb: 6,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mb: 4,
          fontWeight: "medium",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          height: 400,
          width: "100%",
          "& .recharts-wrapper": {
            mb: 2,
          },
        }}
      >
        {children}
      </Box>
    </Paper>
  );

  // Calculate the total defect count from the defectRate data.
  const totalDefectCount =
    dashboardData.defectRate && Array.isArray(dashboardData.defectRate)
      ? dashboardData.defectRate.reduce(
          (sum, entry) => sum + (entry.defect_count || 0), // Ensure defect_count exists
          0
        )
      : 0;

  const formatYAxis = (value) => {
    return `${value}%`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload; // Get the full data for the point
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`Station: ${label}`}</p>
          <p>{`Tasa: ${dataPoint.defect_rate}%`}</p>
          <p>{`Nº de Defectos: ${dataPoint.defect_count || 0}`}</p>{" "}
          {/* Display defect_count */}
        </div>
      );
    }

    return null;
  };

  return (
    <TabPanel value={tabValue} index={5}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ChartContainer title="Tasa de Defectos">
            <ResponsiveContainer>
              <LineChart data={dashboardData.defectRate}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="station" />
                <YAxis tickFormatter={formatYAxis} />
                {/* <Tooltip formatter={(value) => [`${value}%`, null]}/> */}
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="defect_rate"
                  stroke={theme.palette.error.main}
                  name={`% Defectos (Total: ${totalDefectCount})`}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default DefectRateChart;
