import React, { useState, useEffect } from "react";
import config from "./config.js";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns"; // Import parseISO
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import axios from "axios";
import { useTranslation } from "react-i18next";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const WorkShiftScheduler = () => {
  const { t, i18n } = useTranslation();
  const [shifts, setShifts] = useState(
    daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: [] }), {})
  );
  const fetchShifts = async () => {
    try {
      const response = await axios.get(config.url + "/shifts");
      const shiftsData = response.data.reduce((acc, shift) => {
        const { day_of_week, start_time, end_time, id, end_day } = shift;
        if (!acc[day_of_week]) acc[day_of_week] = [];

        // Parse start_time and end_time to Date objects
        const startDate = parseISO(`1970-01-01T${start_time}:00`);
        const endDate = parseISO(`1970-01-01T${end_time}:00`);

        acc[day_of_week].push({
          startTime: startDate,
          endTime: endDate,
          id,
          endDay: end_day,
        });
        return acc;
      }, {});
      setShifts(shiftsData);
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  };

  useEffect(() => {
    fetchShifts();
  }, []);

  const handleShiftChange = (day, index, field, value) => {
    setShifts((prevShifts) => ({
      ...prevShifts,
      [day]:
        prevShifts[day]?.map((shift, i) =>
          i === index ? { ...shift, [field]: value } : shift
        ) || [],
    }));
  };

  const addShift = (day) => {
    setShifts((prevShifts) => ({
      ...prevShifts,
      [day]: [
        ...(prevShifts[day] || []),
        { startTime: null, endTime: null, endDay: "SAME_DAY", id: null },
      ],
    }));
  };

  const removeShift = async (day, index) => {
    const shift = shifts[day][index];
    // Only make an API call if the shift has an ID (i.e., it exists in the backend)
    if (shift.id) {
      try {
        await axios.delete(config.url + `/shifts/${shift.id}`);
        console.log(`Shift ${shift.id} deleted successfully`);
      } catch (error) {
        console.error(`Error deleting shift ${shift.id}:`, error);
        return;
      }
    }
    // Update the shifts state locally by filtering out the deleted shift
    setShifts((prevShifts) => ({
      ...prevShifts,
      [day]: prevShifts[day].filter((_, i) => i !== index),
    }));
  };

  const saveShiftsToBackend = async () => {
    try {
      const newShifts = [];
      const updatedShifts = [];
      let invalidShiftFound = false;

      daysOfWeek.forEach((day) => {
        (shifts[day] || []).forEach((shift) => {
          if (!shift.startTime || !shift.endTime) return;

          // Check if end time is invalid
          const isNextDay = shift.endDay === "NEXT_DAY";
          const isValidTime = isNextDay || shift.endTime > shift.startTime;

          if (!isValidTime) {
            invalidShiftFound = true;
            console.error(
              `Invalid time for shift on ${day}: endTime must be after startTime on the same day.`
            );
            return;
          }

          const payload = {
            day_of_week: day,
            start_time: shift.startTime.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            }),
            end_time: shift.endTime.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            }),
            end_day: shift.endDay || "SAME_DAY",
          };

          if (shift.id) {
            updatedShifts.push({ ...payload, id: shift.id });
          } else {
            newShifts.push(payload);
          }
        });
      });

      if (invalidShiftFound) {
        alert("One or more shifts have an invalid end time.");
        return; // Prevent API calls if there’s an invalid shift
      }

      // Process PUT requests for existing shifts
      for (const shift of updatedShifts) {
        await axios.put(config.url + `/shifts/${shift.id}`, shift);
      }

      // Send POST request for new shifts if there are any
      if (newShifts.length > 0) {
        await axios.post(config.url + "/shifts", {
          shifts: newShifts,
        });
      }

      fetchShifts();
      console.log("Shifts saved successfully");
      alert("Shifts saved successfully!");
    } catch (error) {
      console.error("Error saving shifts:", error);
      alert("Failed to save shifts. Please try again.");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ maxWidth: 600, margin: "auto", p: 2 }}>
        <Typography variant="h4" gutterBottom>
          {t("weekly_work_shifts")}
        </Typography>
        {daysOfWeek.map((day) => (
          <Card key={day} variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">{t(day)}</Typography>
              {(shifts[day] || []).map((shift, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid item xs={4}>
                    <TimePicker
                      label={t("start_time")}
                      value={shift.startTime}
                      onChange={(newValue) =>
                        handleShiftChange(day, index, "startTime", newValue)
                      }
                      slots={{ textField: TextField }}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimePicker
                      label={t("end_time")}
                      value={shift.endTime}
                      onChange={(newValue) =>
                        handleShiftChange(day, index, "endTime", newValue)
                      }
                      slots={{ textField: TextField }}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      label="Ends"
                      value={shift.endDay || "SAME_DAY"}
                      onChange={(e) =>
                        handleShiftChange(day, index, "endDay", e.target.value)
                      }
                      fullWidth
                    >
                      <MenuItem value="SAME_DAY">{t("same_day")}</MenuItem>
                      <MenuItem value="NEXT_DAY">{t("next_day")}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color="error"
                      onClick={() => removeShift(day, index)}
                      disabled={shifts[day].length === 1}
                    >
                      <RemoveCircle />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button
                variant="text"
                startIcon={<AddCircle />}
                onClick={() => addShift(day)}
                sx={{ mt: 1 }}
              >
                {t("add_shift")}
              </Button>
            </CardContent>
          </Card>
        ))}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={saveShiftsToBackend}
        >
          {t("save_shifts")}
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default WorkShiftScheduler;
