//This file contains the LogisticsContent component which is the content of the logistics page. It contains the content items for the logistics page.
//This file contains the OperatorContent component which is the content of the operator page. It contains the content items for the operator page.
import React, { useState } from "react";
import config from "./config";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Logout from "./Logout";
import Tags from "./Tags";
import Stations from "./Stations";
import Carts from "./Carts";
import LogisticsPO from "./LogisticsPO";
import Users from "./Users";
import AntennaManager from "./AntennaManager";
import Charts from "./Charts";
import AboutUs from "./AboutUs";
import Incidents from "./Incidents";
import Documentation from "./Documentation";
import { useTranslation } from "react-i18next";
import "./styles.css";
import UserSettings from "./Settings";
import VerticalWarehouse from "./VerticalWarehouse";

const LogisticsContent = ({
  selectedSection,
  subscribedPOId,
  setSubscribedPOId,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
      <br />
      <br />
      <br />

      {selectedSection === "Production Orders" && <LogisticsPO />}

      {selectedSection === "VerticalWarehouse" && <VerticalWarehouse />}
      {selectedSection === "About Us" && <AboutUs />}

      {selectedSection === "settings" && <UserSettings />}
    </Box>
  );
};

export default LogisticsContent;
