import React, { useState, useEffect } from "react";
import config from "./config.js";

import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "./styles.css";
import axios from "axios";
import { set } from "date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importar la localización en español
import "dayjs/locale/en"; // Importar la localización en inglés
import "dayjs/locale/fr"; // Importar la localización en francés
import "dayjs/locale/de"; // Importar la localización en alemán
import "dayjs/locale/it"; // Importar la localización en italiano
import "dayjs/locale/zh"; // Importar la localización en chino

const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZone: config.timeZone,
  timeZoneName: "short", // To include the GMT information
};
const Incidents = ({ subscribedPOId }) => {
  const [incidents, setIncidents] = useState([]);
  const [open, setOpen] = useState(false);
  const [stations, setStations] = useState([]); // Add station state
  const [currentProductionOrderId, setCurrentProductionOrderId] = useState("");
  const [currentStationId, setCurrentStationId] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().subtract(14, "day"));

  const [endDate, setEndDate] = useState(null);
  const { t, i18n } = useTranslation();

  dayjs.locale(i18n.language);
  const [currentIncident, setCurrentIncident] = useState({
    id: null,
    station_id: "",
    description: "",
    date: "",
    status: "",
    production_order_id: "",
    user_id: "",
  });

  useEffect(() => {
    console.log(subscribedPOId);
    console.log("Fetching incidents...");

    fetchStations();
    fetchCurrentUserElementIds();
  }, []);
  const fetchCurrentUserElementIds = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/user/${localStorage.getItem("username")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        if (data.station_id === null) {
          setCurrentStationId(0);
        } else {
          setCurrentStationId(data.station_id);
        }
        if (data.production_order_id) {
          setCurrentProductionOrderId(data.production_order_id);
        }
        fetchIncidents(startDate, endDate);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
      }
      console.error("Error fetching user:", error);
    }
  };
  const fetchStations = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + `/stations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setStations(response.data);
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
      }
      console.error("Error fetching stations:", error);
    }
  };

  const fetchIncidents = async (startDate, endDate) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/incidents/0`, // what if it is null? What would the url be?
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
            endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
          },
        }
      );
      console.log(response);
      setIncidents(response.data);
    } catch (error) {
      console.error("Error fetching incidents:", error);
    }
  };

  const handleEdit = (incident) => {
    setCurrentIncident(incident);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      await fetch(config.url + `/incidents/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchIncidents();
    } catch (error) {
      console.error("Error deleting incident:", error);
    }
  };

  const handleSave = async () => {
    try {
      console.log("incident: ", currentIncident);
      const accessToken = localStorage.getItem("access_token");
      const url =
        config.url +
        `/incidents${currentIncident.id ? `/${currentIncident.id}` : ""}`;
      const method = currentIncident.id ? "PUT" : "POST";

      const response = await axios({
        method: method,
        url: url,
        data: currentIncident,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log(response);
      fetchIncidents(startDate, endDate);
      setOpen(false);
    } catch (error) {
      console.error("Error saving incident:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCurrentIncident({ ...currentIncident, [name]: value });
    console.log("incident: ", currentIncident);
  };
  const refreshStartDateData = async (startDate) => {
    console.log("Start Date:", startDate);
    setStartDate(startDate);
    fetchIncidents(startDate, endDate);
  };

  const refreshEndDateData = async (endDate) => {
    setEndDate(endDate);
    fetchIncidents(startDate, endDate);
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("incidents")}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
        {console.log("Lang:", i18n.language)}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <DatePicker
            label={t("first_initial_date")}
            format="DD/MM/YYYY"
            value={startDate}
            onChange={(newValue) => refreshStartDateData(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label={t("last_initial_date")}
            format="DD/MM/YYYY"
            value={endDate}
            onChange={(newValue) => refreshEndDateData(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </LocalizationProvider>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setCurrentIncident({
            production_order_id: subscribedPOId,
            station_id: localStorage.getItem("station_id"),
          });
          setOpen(true);
        }}
      >
        {t("add_incident")}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("station")}</TableCell>
              <TableCell>{t("description")}</TableCell>
              <TableCell>{t("date")}</TableCell>
              <TableCell>{t("resolution_date")}</TableCell>
              <TableCell>{t("status")}</TableCell>
              <TableCell>{t("production_order_id")}</TableCell>
              <TableCell>{t("user")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.map((incident) => (
              <TableRow key={incident.id}>
                <TableCell>{incident.station_code}</TableCell>
                <TableCell>{incident.description}</TableCell>
                <TableCell>
                  {new Intl.DateTimeFormat(config.langTZ, options).format(
                    new Date(incident.date)
                  )}
                </TableCell>
                <TableCell>
                  {incident.resolution_date &&
                    new Intl.DateTimeFormat(config.langTZ, options).format(
                      new Date(incident.resolution_date)
                    )}
                </TableCell>
                <TableCell>{t(incident.status.toLowerCase())}</TableCell>
                <TableCell>{incident.production_order_id}</TableCell>
                <TableCell>{incident.username}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(incident)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(incident.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentIncident.id ? t("edit_incident") : t("add_incident")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl fullWidth margin="dense">
              <InputLabel id="station-select-label">{t("station")}</InputLabel>
              <Select
                name="station_id"
                labelId="station-select-label"
                value={
                  currentIncident.id
                    ? currentIncident.station_id
                    : localStorage.getItem("station_id")
                }
                onChange={handleChange}
              >
                {stations.map((station) => (
                  <MenuItem key={station.id} value={station.id}>
                    {station.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                name="description"
                label={t("description")}
                value={currentIncident.description}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="status-select-label">{t("status")}</InputLabel>
              <Select
                name="status"
                labelId="status-select-label"
                value={currentIncident.status}
                onChange={handleChange}
              >
                <MenuItem key="1" value="Open">
                  {t("open")}
                </MenuItem>
                <MenuItem key="2" value="Closed">
                  {t("closed")}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                name="production_order_id"
                label={t("production_order_id")}
                value={
                  currentIncident.id
                    ? currentIncident.production_order_id
                    : subscribedPOId
                }
                onChange={handleChange}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>{t("save")}</Button>
          <Button onClick={handleClose}>{t("cancel")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Incidents;
