import React, { useState, useEffect } from 'react';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer
} from 'recharts';
import { 
  TextField, Grid, Box, Typography, MenuItem, Autocomplete,
  Paper, Tabs, Tab, Card, CardContent, CircularProgress, Alert,
  Button
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import config from "./config.js";
import es from 'date-fns/locale/es';

// API Configuration
const API_BASE_URL = config.url;

const fetchData = async (endpoint) => {
  const token = localStorage.getItem('access_token');
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`Error fetching from ${endpoint}:`, error);
    throw error;
  }
};

const MetricCard = ({ title, value, color }) => (
  <Card raised>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" color={color}>
        {value?.toFixed(1)}%
      </Typography>
    </CardContent>
  </Card>
);

export default function OEEDashboard() {
  const [activeTab, setActiveTab] = useState(0);
  const [metrics, setMetrics] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date()
  });
  const [stationId, setStationId] = useState('');
  const [stations, setStations] = useState([]);
  const [productionOrderId, setProductionOrderId] = useState('');
  const [productionOrders, setProductionOrders] = useState([]);

  useEffect(() => {
    const loadStations = async () => {
      try {
        const data = await fetchData('/stations');
        setStations(data);
      } catch (err) {
        setError('Error cargando estaciones: ' + err.message);
      }
    };
    
    loadStations();
  }, []);

  const handleSearch = async () => {
    if (!stationId) {
      setError('Por favor seleccione una estación');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const formattedStartDate = dateRange.startDate.toISOString().split('T')[0];
      const formattedEndDate = dateRange.endDate.toISOString().split('T')[0];

      const data = await fetchData(
        `/station-oee/metrics?station_id=${stationId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
      );

      if (data.metrics) {
        setMetrics(data.metrics);
        setHistoricalData(data.history || []);
      } else {
        setError('No se encontraron datos para el período seleccionado');
      }
    } catch (err) {
      setError('Error obteniendo métricas: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Box className="p-4">
        <Typography variant="h4" className="mb-4">Panel OEE</Typography>
        
        {error && (
          <Alert severity="error" className="mb-4" onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <Paper className="mb-4 p-4">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Fecha de Inicio"
                value={dateRange.startDate}
                onChange={(newValue) => setDateRange(prev => ({ ...prev, startDate: newValue }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Fecha de Fin"
                value={dateRange.endDate}
                onChange={(newValue) => setDateRange(prev => ({ ...prev, endDate: newValue }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Estación"
                value={stationId}
                onChange={(e) => setStationId(e.target.value)}
                fullWidth
              >
                {stations.map((station) => (
                  <MenuItem key={station.id} value={station.id}>
                    {station.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleSearch}
                disabled={loading}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : 'Buscar'}
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {metrics && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <MetricCard 
                title="Disponibilidad" 
                value={metrics.availability} 
                color="primary.main"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MetricCard 
                title="Rendimiento" 
                value={metrics.performance} 
                color="success.main"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MetricCard 
                title="Calidad" 
                value={metrics.quality} 
                color="warning.main"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MetricCard 
                title="OEE" 
                value={metrics.oee} 
                color="secondary.main"
              />
            </Grid>

            {historicalData.length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" className="mb-4">Histórico OEE</Typography>
                    <Box className="h-96">
                      <ResponsiveContainer>
                        <LineChart data={historicalData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="date" 
                            tickFormatter={(value) => new Date(value).toLocaleDateString('es-ES')}
                          />
                          <YAxis domain={[0, 100]} />
                          <Tooltip 
                            formatter={(value) => value.toFixed(2) + '%'}
                          />
                          <Legend />
                          <Line type="monotone" dataKey="availability" name="Disponibilidad" stroke="#1976d2" />
                          <Line type="monotone" dataKey="performance" name="Rendimiento" stroke="#2e7d32" />
                          <Line type="monotone" dataKey="quality" name="Calidad" stroke="#ed6c02" />
                          <Line type="monotone" dataKey="oee" name="OEE" stroke="#9c27b0" />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </LocalizationProvider>
  );
}