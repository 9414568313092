import React from "react";
import { Bar, Line, Scatter, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const colors = [
  'rgb(255, 99, 132)',
  'rgb(54, 162, 235)',
  'rgb(255, 206, 86)',
  'rgb(75, 192, 192)',
  'rgb(153, 102, 255)',
  'rgb(255, 159, 64)',
  'rgb(255, 99, 132)',
  'rgb(201, 203, 207)'
];

function ChartDisplay({ data, visualizationType }) {
  if (!data || data.length === 0) {
    return <p>No data to display or loading...</p>;
  }

  const detectDataStructure = (data) => {
    const keys = Object.keys(data[0]);
    
    if (keys.length === 2) {
      const numericKey = keys.find(key => 
        !isNaN(data[0][key]) && typeof data[0][key] !== 'boolean'
      );
      const labelKey = keys.find(key => key !== numericKey);
      
      if (numericKey && labelKey) {
        return {
          type: "frequency",
          labelKey,
          valueKey: numericKey
        };
      }
    }
    
    return {
      type: "default",
      labelKey: keys[0],
      dataKeys: keys.slice(1)
    };
  };

  const dataStructure = detectDataStructure(data);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Data Analysis - ${visualizationType}`,
      },
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const getChartData = () => {
    if (dataStructure.type === "frequency") {
      return {
        labels: data.map(item => item[dataStructure.labelKey]),
        datasets: [{
          label: dataStructure.valueKey,
          data: data.map(item => item[dataStructure.valueKey]),
          backgroundColor: colors.slice(0, data.length),
          borderColor: colors.slice(0, data.length),
        }]
      };
    }

    return {
      labels: data.map(item => item[dataStructure.labelKey]),
      datasets: dataStructure.dataKeys.map((key, index) => ({
        label: key,
        data: data.map(item => item[key]),
        backgroundColor: colors[index % colors.length],
        borderColor: colors[index % colors.length],
      }))
    };
  };

  const chartData = getChartData();

  const renderChart = () => {
    switch (visualizationType.toLowerCase()) {
      case "bar chart":
        return <Bar data={chartData} options={options} />;
      case "line chart":
        return <Line data={chartData} options={options} />;
      case "pie chart":
        return <Pie data={chartData} options={options} />;
      case "scatter plot":
        if (dataStructure.type === "default" && dataStructure.dataKeys.length >= 2) {
          const scatterData = {
            datasets: [{
              label: `${dataStructure.dataKeys[0]} vs ${dataStructure.dataKeys[1]}`,
              data: data.map(item => ({
                x: item[dataStructure.dataKeys[0]],
                y: item[dataStructure.dataKeys[1]]
              })),
              backgroundColor: colors[0]
            }]
          };
          return <Scatter data={scatterData} options={options} />;
        }
        return null;
      default:
        return (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <thead>
                <tr>
                  {Object.keys(data[0]).map(key => (
                    <th key={key} className="border border-gray-300 px-4 py-2 bg-gray-100">
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {Object.keys(item).map(key => (
                      <td key={key} className="border border-gray-300 px-4 py-2">
                        {item[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
    }
  };

  return (
    <div className="w-full p-4 bg-white rounded-lg shadow">
      {renderChart()}
    </div>
  );
}

export default ChartDisplay;