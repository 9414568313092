// StationStats.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// Utility function to format seconds into HH:MM:SS
const formatSeconds = (totalSeconds) => {
  if (totalSeconds === null || totalSeconds === undefined) return "-";

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${hours}h ${minutes}m ${seconds}s`;
};

const StationStats = ({ statistics }) => {
  const { t } = useTranslation();

  // Optional: Fetch station type names if you have a mapping
  // For now, we'll just display the station_type_id

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" className="custom-h4">
        {t("production_order_statistics")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("station_type_id")}</TableCell>
              <TableCell>{t("expected_time")}</TableCell>
              <TableCell>{t("real_time")}</TableCell>
              <TableCell>{t("operators_time")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(statistics).map(([stationTypeId, stats]) => (
              <TableRow key={stationTypeId}>
                <TableCell>{stationTypeId}</TableCell>
                <TableCell>{formatSeconds(stats.expected_time)}</TableCell>
                <TableCell>{formatSeconds(stats.real_time)}</TableCell>
                <TableCell>{formatSeconds(stats.operators_time)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StationStats;
