export const sendMessageToBackend = async (message) => {
  const response = await fetch("http://localhost:5000/api/chat", {
    // Flask backend URL
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data; // { text: "Respuesta del backend", chartData: { ...datos del gráfico... } }
};
