import React, { useState, useEffect } from "react";
import config from "./config.js";
import axios from "axios";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { handleLogout } from "./Logout";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

const AntennaManager = () => {
  const [antennas, setAntennas] = useState([]);
  const [lines, setLines] = useState([]); // Add line state
  const [stations, setStations] = useState([]); // Add station state
  const [open, setOpen] = useState(false);
  const [currentAntenna, setCurrentAntenna] = useState({
    id: null,
    name: "",
    source_name: "",
    host: "",
    port: "",
    line_id: "",
    station_id: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    source_name: "",
    host: "",
    port: "",
    line_id: "",
    station_id: "",
    last_updated: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Fetching antennas...");
    fetchAntennas();
    fetchLines(); // Fetch lines when component mounts
  }, []);

  useEffect(() => {
    if (formData.station_id) {
      fetchStationsForGivenLine(formData.line_id);
    }
  }, [formData.line_id]);

  const fetchAntennas = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.get(config.url+"/antennas", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data); // Use the data as needed
        setAntennas(response.data);
      } else {
        // Handle errors
        console.error("Failed to fetch dashboard data");
      }
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error fetching antennas:", error);
    }
  };
  const fetchLines = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url+"/lines", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setLines(response.data);
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error fetching lines:", error);
    }
  };
  const fetchStationsForGivenLine = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url+`/lines/${id}/stations`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      setStations(response.data);
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error fetching stations:", error);
    }
  };

  const handleOpen = (
    antenna = {
      id: null,
      name: "",
      source_name: "",
      host: "",
      port: "",
      line_id: "",
      station_id: "",
    }
  ) => {
    setCurrentAntenna(antenna);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCurrentAntenna({ ...currentAntenna, [name]: value });
    if (name === "line_id") {
      fetchStationsForGivenLine(value);
    }
  };

  const handleSave = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (currentAntenna.id) {
      await axios.put(
        config.url+`/antennas/${currentAntenna.id}`,
        currentAntenna,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } else {
      // Create new antenna
      await axios.post(config.url+"/antennas", currentAntenna, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    fetchAntennas();
    handleClose();
  };

  const handleEditClick = (antenna) => {
    setCurrentAntenna(antenna);
    setFormData(antenna);
    setOpen(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      await axios.delete(config.url+`/antennas/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchAntennas();
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error deleting antenna:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("antennas")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpen()}
      >
        {t("add_antenna")}
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("source_name")}</TableCell>
              <TableCell>{t("host")}</TableCell>
              <TableCell>{t("port")}</TableCell>
              <TableCell>{t("line_name")}</TableCell>
              <TableCell>{t("station_name")}</TableCell>
              <TableCell>{t("last_updated")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {antennas.map((antenna) => (
              <TableRow key={antenna.id}>
                <TableCell>{antenna.name}</TableCell>
                <TableCell>{antenna.source_name}</TableCell>
                <TableCell>{antenna.host}</TableCell>
                <TableCell>{antenna.port}</TableCell>
                <TableCell>{antenna.line_name}</TableCell>
                <TableCell>{antenna.station_name}</TableCell>
                <TableCell>{antenna.last_updated}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleEditClick(antenna)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteClick(antenna.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentAntenna.id ? t("edit_antenna") : t("add_antenna")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentAntenna.id
              ? t("edit_antenna_details")
              : t("add_new_antenna_details")}
          </DialogContentText>
          <TextField
            name="name"
            value={currentAntenna.name}
            onChange={handleChange}
            label={t("name")}
            fullWidth
            margin="dense"
          />
          <TextField
            name="source_name"
            value={currentAntenna.source_name}
            onChange={handleChange}
            label={t("source_name")}
            fullWidth
            margin="dense"
          />
          <TextField
            name="host"
            value={currentAntenna.host}
            onChange={handleChange}
            label={t("host")}
            fullWidth
            margin="dense"
          />
          <TextField
            name="port"
            value={currentAntenna.port}
            onChange={handleChange}
            label={t("port")}
            fullWidth
            margin="dense"
          />

          <FormControl fullWidth margin="dense">
            <InputLabel id="line-select-label">{t("line")}</InputLabel>
            <Select
              name="line_id"
              labelId="line-select-label"
              value={currentAntenna.line_id}
              onChange={handleChange}
            >
              {lines.map((line) => (
                <MenuItem key={line.id} value={line.id}>
                  {line.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel id="station-select-label">{t("station")}</InputLabel>
            <Select
              name="station_id"
              labelId="station-select-label"
              value={currentAntenna.station_id}
              onChange={handleChange}
            >
              {stations.map((station) => (
                <MenuItem key={station.id} value={station.id}>
                  {station.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AntennaManager;
